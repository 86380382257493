import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Box, InputBase } from "@material-ui/core";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SearchIcon from "@material-ui/icons/Search";
import IownLogo from "../../assets/iown.png";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory, Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDataByID } from "../../redux/actions/userAction";
import {
  getAllCities,
  getAllTages,
  ChangeLocation,
} from "../../redux/actions/generalAction";
import { axiosClient } from "../../helper";
import { emptyBasket } from "../../redux/actions/basketAction";
import { BsInfoCircle } from "react-icons/bs";
import "./style.css";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userReducer);
  const { categories } = useSelector((state) => state.categoriesReducer);
  const { basket } = useSelector((state) => state.basketReducer);
  const { allCities, allTages, choosedLocation } = useSelector(
    (state) => state.generalReducer
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [dropDown, setDropdown] = useState(false);
  const [loginBtn, setLoginBtn] = useState(localStorage.getItem("token"));
  const [searchCategory, setSearchCategory] = useState("all");
  const [searchProduct, setSearchProduct] = useState("");
  const [display, setDisplay] = useState(false);
  const [choosedCity, setChooseCity] = useState(null);
  const [selectedCity, setSelectedCity] = useState("All");
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState([]);
  const wrapperRef = useRef(null);
  console.log("userData", userData, dropDown, loginBtn);
  useEffect(() => {
    if (userData !== undefined || Object?.keys(userData)?.length >= 0) {
      dispatch(GetUserDataByID());
    }
    if (!allCities || allCities?.length == 0) {
      dispatch(getAllCities());
    }
    if (!allTages || allTages?.length == 0) {
      dispatch(getAllTages());
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setLoginBtn(true);
    } else {
      setLoginBtn(false);
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;

    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const onChangeCity = (e) => {
    if (e.target.value) {
      setSelectedCity(e.target.value);
      setChooseCity(JSON.parse(e.target.value));
    }
  };
  const handleSearchCategory = (e) => {
    console.log("e.target.value", e.target.value);
    setSearchCategory(e.target.value);
    setShowDropdown([]);
    setSearchProduct("");
  };
  const handleSearchOnKey = (e) => {
    if (e.key === "Enter") {
      setDisplay(false);
      handleSearch();
    }
  };
  const onChangeSearch = (tag) => {
    setSearchProduct(tag);
    setDisplay(false);
    searchOnSelectTag(tag);
  };

  const handleSearch = () => {
    if (!searchProduct) return "";
    history.push(
      `/searchresults/${searchCategory}/${searchProduct}/${pageNumber}/${choosedLocation}`
    );
  };
  const searchOnSelectTag = (tag) => {
    if (!tag) return "";
    history.push(
      `/searchresults/${searchCategory}/${tag}/${pageNumber}/${choosedLocation}`
    );
  };

  const handleNavigate = (val) => {
    if (val == "login") {
      history.push("/login");
    } else if (val == "checkout") {
      history.push("/checkout");
    } else if (val == "wishList") {
      history.push("/wishList");
    } else if (val == "myAccount") {
      setDropdown(false);
      history.push("/manage-profile");
    } else if (val == "signOut") {
      setDropdown(false);
      localStorage.clear();
      window.location = "/login";
    }
  };
  const handleDropDown = (val) => {
    setDropdown(val);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  ///////////////////////////////////////////////////////////////////////////////
  return (
    <Grid container xs={12}>
      <Grid container item xs={12} className="header-main-grid-green">
        <Grid item xs={12} className="wrapper">
          <Grid style={{ display: "flex", marginLeft: "10px" }}>
            {choosedLocation ? (
              <div className="header__address" onClick={handleShow}>
                <div className="header__address-icon">
                  <LocationOnIcon
                    style={{ color: "white", marginLeft: "10px" }}
                  />
                </div>
                <div className="header__option" style={{ marginLeft: "2px" }}>
                  <span
                    className="header__optionLineOne"
                    style={{ marginBottom: "-2px" }}
                  >
                    Deliver to
                  </span>
                  <span className="header__optionLineTwo text-capitalize">
                    {choosedLocation === "All" ? "Pakistan" : choosedLocation}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <Typography className="track-your-orders">
              track your orders
            </Typography> */}
          </Grid>
          <Grid style={{ display: "flex" }}>
            <Box style={{ position: "relative" }}>
              {loginBtn ? (
                <React.Fragment>
                  {dropDown ? (
                    <Typography
                      className="userName"
                      onClick={() => handleDropDown(false)}
                    >
                      {userData?.name} <ArrowDropDownIcon />
                    </Typography>
                  ) : (
                    <Typography
                      className="userName"
                      onClick={() => handleDropDown(true)}
                    >
                      {userData?.name} <ArrowDropDownIcon />
                    </Typography>
                  )}
                  {dropDown ? (
                    <Grid className="header-menu">
                      <Grid
                        style={{ padding: "5px" }}
                        className="header-menuItem"
                        onClick={() => handleNavigate("myAccount")}
                      >
                        My Account
                      </Grid>
                      <Grid
                        style={{ padding: "5px" }}
                        className="header-menuItem"
                        onClick={() => handleNavigate("signOut")}
                      >
                        Sign Out
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigate("login")}
                >
                  <PersonOutlineIcon
                    className="header-three-icons"
                    style={{ marginTop: "15px", width: "40px", height: "35px" }}
                  />
                  <h4 style={{ color: "white", marginTop: "5px" }}>Login</h4>
                </div>
              )}
            </Box>
            <StarBorderIcon
              className="header-three-icons"
              onClick={() => handleNavigate("wishList")}
              style={{ marginTop: "25px", width: "40px", height: "35px" }}
            />
            <Link to="/checkout">
              <div className="header__optionBasket">
                <ShoppingBasketIcon style={{ width: "40px", height: "35px" }} />
                <span className="header__optionLineTwo header__basketCount ml-1">
                  {basket?.length}
                </span>
              </div>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className="header-main-grid-white"
        style={{ paddingRight: "10px", paddingLeft: "10px" }}
      >
        <Grid container item xs={12} className="wrapper">
          <Grid item xs={6} sm={4} md={3} className="iown-grid">
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => history.push("/")}
            >
              <img src={IownLogo} className="iown-image" />
              <Typography className="iOwn-text">iOwn.store</Typography>
            </div>
          </Grid>
          <Grid container item xs={6} sm={8} md={4}>
            <Grid item xs={12} sm={5}>
              <Grid item xs={12} className="header-contactUs-grid">
                <Box className="contact-icon-box">
                  <PhoneIcon className="contact-icon" />
                </Box>
                <Typography style={{ marginLeft: "7px" }}>
                  <Typography className="header-contactUs-label">
                    Contact Us
                  </Typography>
                  <Typography className="header-contactUs-value">
                    {/* {userData?.phone} */}
                    03494864273
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid item xs={12} className="header-contactUs-grid">
                <Box className="contact-icon-box">
                  <MailOutlineIcon className="contact-icon" />
                </Box>
                <Typography style={{ marginLeft: "7px" }}>
                  <Typography className="header-contactUs-label">
                    Email
                  </Typography>
                  <Typography className="header-contactUs-value">
                    hello@iown.pk
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={5}>
            <Grid container item xs={12} className="header-searchBar-Grid">
              <Grid item xs={6}>
                <InputBase
                  fullWidth
                  placeholder="search"
                  className="header-search-input"
                  onClick={() => setDisplay(!display)}
                  value={searchProduct}
                  onChange={(e) => {
                    axiosClient()
                      .get(
                        `/searchProductCategories/${searchCategory}/${e.target.value}`
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          setDisplay(true);
                          setShowDropdown(response.data.products);
                        }
                      })
                      .catch((error) => {
                        // console.log(error);
                      });
                    setSearchProduct(e.target.value);
                  }}
                  onKeyPress={handleSearchOnKey}
                />
                {display && (
                  <div className="autoContainer" ref={wrapperRef}>
                    {showDropdown &&
                      showDropdown.length > 0 &&
                      showDropdown.map((value, i) => {
                        return (
                          <div
                            onClick={() => onChangeSearch(value)}
                            className="option"
                            key={i}
                            tabIndex="0"
                          >
                            <span>{value}</span>
                          </div>
                        );
                      })}
                    {/* {allTages
                      ?.filter(
                        (name) => name.indexOf(searchProduct.toLowerCase()) > -1
                      )
                      .map((value, i) => {
                        return (
                          <div
                            onClick={() => onChangeSearch(value)}
                            className="option"
                            key={i}
                            tabIndex="0"
                          >
                            <span>{value}</span>
                          </div>
                        );
                      })} */}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth className="header-select">
                  <InputLabel
                    fullWidth
                    htmlFor="age-native-simple"
                    style={{ marginLeft: "10px" }}
                  >
                    All Categories
                  </InputLabel>
                  <Select
                    fullWidth
                    native
                    value={searchCategory}
                    onChange={handleSearchCategory}
                  >
                    <option value="all">All</option>
                    {categories?.categories?.map((cat) => (
                      <option
                        key={cat._id}
                        value={cat._id}
                        className="text-capitalize"
                      >
                        {cat.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Box className="header-search-box">
                  <SearchIcon className="searchIcon" onClick={handleSearch} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal show={show} onHide={handleClose} className="city-popup">
        <Modal.Header closeButton>
          <Modal.Title>Choose your location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={12}
              style={{ color: "#5a5858", fontSize: "14px" }}
            >
              Select Location
            </Form.Label>
            <Col sm={12} md={12} lg={12}>
              <Form.Control
                as="select"
                value={selectedCity}
                onChange={onChangeCity}
                style={{ boxShadow: "0px 0px 10px #cfcfcf" }}
              >
                <option style={{ margin: "5px" }} value="" disabled>
                  -- Choose a location --
                </option>
                <option selected value={choosedLocation}>
                  {choosedLocation}
                </option>
                {allCities?.map(
                  (city) =>
                    choosedLocation !== city.cityName && (
                      <option
                        key={city._id}
                        value={JSON.stringify(city)}
                        className="text-capitalize"
                      >
                        {city.cityName}
                      </option>
                    )
                )}
              </Form.Control>
            </Col>
          </Form.Group>
          <div className="modal-info">
            <BsInfoCircle className="mt-1 mr-1" />{" "}
            <div>
              Please note that all products from your cart will be removed on
              changing location
            </div>
          </div>{" "}
          <div
            style={{
              margin: "10px",

              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={choosedCity == null}
              onClick={() => {
                dispatch(ChangeLocation(choosedCity));
                dispatch(emptyBasket());
                setShow(false);
                setChooseCity(null);
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Grid>
  );
};
export default Index;
