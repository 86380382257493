import React, { useEffect } from "react";
import "./App.css";
// import Header from "./Header";
import Header from "./components/Header";
import Navbar from "./components/NewNavBar";
import Home from "./pages/Home/Home";
import NewHome from "./pages/Home/NewHome";
import Shop from "./pages/Shop/Shop";
import SubcategoryShop from "./pages/subCategoryShop/SubcategoryShop";
import SearchResults from "./pages/searchResults/SearchResults";
import SearchNotFound from "./pages/searchNotFound/searchNotFound";
import ProductDetail from "./pages/ProductDetails/Products";
import WishList from "./pages/wishList/wishList";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Checkout from "./Checkout";
import Login from "./Login";
import Signup from "./Signup";
import IWant from "./pages/iWant/IWant";
import Orders from "./Orders";
// import Footer from "../src/components/Footer/Footer";
import Footer from "./components/NewFooter";
import CheckOut from "./pages/Checkout/checkout";
import whatsapp from "./assets/whatsapp.png";
import ManageProfile from "./pages/ManageProfile/ManageProfile";
import MyOrders from "./pages/myOrders/MyOrders";
import ReturnRequest from "./pages/myOrders/ReturnRequest";
import ViewReturnRequests from "./pages/myOrders/ViewReturnRequests";
import EmailVerify from "./pages/forgotPassword/emailVerify";
import VerifyCode from "./pages/forgotPassword/verifyCode";
import ResetPassword from "./pages/forgotPassword/resetPassword";
import InvoiceViewer from "./pages/invoice/invoice";
import InvoiceTable from "./pages/invoice/invoiceList";
import IwantRequests from "./pages/iWant/viewiWant/viewiWant";
import LandingPage from "./pages/LandingPage";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import Store from "./redux/store";
import reduxPersistStore from "./redux/store";
let { store, persistor } = reduxPersistStore();
const buyerId = localStorage.getItem("userId");

function App() {
  useEffect(() => {
    let expiry = localStorage.getItem("tokenExpiry");
    let currentDate = new Date();
    // if (currentDate.getTime() > expiry) {
    //   localStorage.clear();
    // }
  }, []);
  return (
    <>
      {/* // BEM */}
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route exact path={`/my-invoices/${buyerId}`}>
                <Header />
                <Navbar />
                <InvoiceTable />
                <Footer />
              </Route>
              <Route exact path={`/invoice-details/:id`}>
                <Header />
                <Navbar />
                <InvoiceViewer />
                <Footer />
              </Route>
              <Route exact path="/">
                <Header />
                <Navbar />
                <LandingPage />
                <Footer />
                {/* <Header />
               
                <Home />
                <Footer /> */}
              </Route>

              <Route path="/reset-password/:code?" component={ResetPassword} />
              <Route path="/verify-code" component={VerifyCode}></Route>
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/email-verify" component={EmailVerify} />

              {/* <Route path="/emailverify ">
            <EmailVerify />
          </Route> */}

              <Route path="/shop/:id/:pageNumber/:location">
                <Header />
                <Navbar />
                <Shop />
                <Footer />
              </Route>
              <Route path="/subshop/:catId/:id/:pageNumber/:location">
                <Header />
                <Navbar />
                <SubcategoryShop />
                <Footer />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/checkout">
                <Header />
                <Navbar />
                <Checkout />
                <Footer />
              </Route>
              <Route path="/orders">
                <Header />
                <Navbar />
                <Orders />
                <Footer />
              </Route>
              <Route exact path="/iwant">
                <Header />
                <Navbar />
                <IWant />
                <Footer />
              </Route>

              <Route exact path="/iwant-requests">
                <Header />
                <Navbar />
                <IwantRequests />
                <Footer />
              </Route>

              <Route path="/searchresults/:categoryId/:productName/:pageNumber/:location">
                <Header />
                <Navbar />
                <SearchResults />
                <Footer />
              </Route>
              <Route path="/searchnotfound">
                <Header />
                <Navbar />
                <SearchNotFound />
                <Footer />
              </Route>
              <Route path="/product-detail/:productId">
                <Header />
                <Navbar />
                <ProductDetail />
                <Footer />
              </Route>
              <Route path="/check-out">
                <CheckOut />
                <Footer />
              </Route>
              <Route path="/wishList">
                <Header />
                <Navbar />
                <WishList />
                <Footer />
              </Route>
              <Route path="/manage-profile">
                <Header />
                <Navbar />
                <ManageProfile />
                <Footer />
              </Route>
              <Route path="/myorders">
                <Header />
                <Navbar />
                <MyOrders />
                <Footer />
              </Route>
              <Route path="/return-request/:id/:productId">
                <Header />
                <Navbar />
                <ReturnRequest />
                <Footer />
              </Route>
              <Route path="/return-requests">
                <Header />
                <Navbar />
                <ViewReturnRequests />
                <Footer />
              </Route>
              <Route path="/landing-page">
                {/* <LandingPage /> */}
                <Header />
                <Navbar />
                <Home />
                <Footer />
              </Route>
            </Switch>
          </Router>
          <a href="https://wa.me/923454007766?text=Hi, can you check the availability of product?">
            <img src={whatsapp} alt="whatsapp-icon" className="whatsapp_icon" />
          </a>
        </PersistGate>
      </ReduxProvider>
    </>
  );
}

export default App;
