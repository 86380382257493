import React, { useState } from "react";
import "./SearchResults.css";
import { withRouter, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ShopFilterSection from "../../components/ShopFilterSection/ShopFilterSection";
// import SearchProductCard from "../../components/searchProductCard/SearchProductCard";
import ShopProductCard from "../../components/ShopProductCard/ShopProductCard";
import SearchNotFound from "../searchNotFound/searchNotFound";
import { axiosClient } from "../../helper";
import { useSelector, useDispatch } from "react-redux";
const SearchResults = (props) => {
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const [results, setResults] = useState([]);
  const [otherProduct, setOtherProduct] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const { categoryId, productName } = useParams();
  console.log("productName", categoryId, productName);
  React.useEffect(() => {
    axiosClient()
      .get(
        `/search/${categoryId}/${productName}/${pageNumber}/${choosedLocation}`
      )
      .then((res) => {
        console.log("res11", res);
        setLoader(false);
        setResults(res.data.products);
        setOtherProduct(res.data.productsWithoutLocation);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

    // let q = categoryId;
    // getCategory();
  }, [categoryId, productName, choosedLocation, pageNumber]);

  // const getCategory = () => {
  //   axiosClient()
  //     .get("/getCategories")
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setCategory(response.data.categories);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (price / 100) * commissionAmount + price;
    } else if (isFixed) {
      return price + commissionAmount;
    } else {
      return price;
    }
  };

  return (
    <>
      <div
        className="container"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        {/* <h1 className="search-heading">Search Results</h1> */}

        <Container>
          <Row className="shop_columns">
            <Col lg={2} md={12}>
              {/* <ShopFilterSection
                handleFilters={(filters) =>
                  handleFilters(filters, "featuredbrands")
                }
                subCategory={subCategory}
                getSubcategory={(id) => getSubcategoryByCategoryId(id)}
                handleSubCategoryId={handleSubCategoryId}
                brand={brand}
                activeItemIndex={activeItemIndex}
                handleToggleBrand={handleToggleBrand}
                checkedBrand={checkedBrand}
                handleToggleLocation={handleToggleLocation}
                checkedLocation={checkedLocation}
                setMinMaxPrice={setMinMaxPrice}
                handleToggleSubCategories={handleToggleSubCategories}
                checkedSubCategories={checkedSubCategories}
              /> */}
            </Col>
            <Col
              lg={10}
              md={12}
              sm={12}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              {results ? (
                <Row>
                  {loader ? (
                    <div className="loaders">
                      <CircularProgress />
                    </div>
                  ) : (
                    results?.map((search) => (
                      <>
                        <Col lg={3} md={6} sm={12}>
                          <ShopProductCard
                            id={search._id}
                            src={search.image[0]}
                            alt={search.name}
                            link={`/product-detail/${search._id}`}
                            title={search.name}
                            brand={search.brand}
                            price={calculatePrice(
                              search.isPercentage,
                              search.isFixed,
                              search.price,
                              parseInt(search.commissionAmount)
                            )}
                            userID={search.userID}
                            ratingValue={search.averageRating}
                            NoOfReviews={search.averageRating}
                            className="search_card_mobile"
                          />
                        </Col>
                      </>
                    ))
                  )}
                </Row>
              ) : (
                <SearchNotFound />
              )}
              {otherProduct && otherProduct.length > 0 && (
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <h2>Other Location</h2>
                </div>
              )}

              {otherProduct ? (
                <Row>
                  {loader ? (
                    <div className="loaders">
                      <CircularProgress />
                    </div>
                  ) : (
                    otherProduct &&
                    otherProduct.length > 0 &&
                    otherProduct?.map((search) => (
                      <>
                        <Col lg={3} md={6} sm={12}>
                          <ShopProductCard
                            id={search._id}
                            src={search.image[0]}
                            alt={search.name}
                            link={`/product-detail/${search._id}`}
                            title={search.name}
                            brand={search.brand}
                            price={calculatePrice(
                              search.isPercentage,
                              search.isFixed,
                              search.price,
                              parseInt(search.commissionAmount)
                            )}
                            userID={search.userID}
                            ratingValue={search.averageRating}
                            NoOfReviews={search.averageRating}
                            locationByCity={search?.locationByCity}
                            className="search_card_mobile"
                          />
                        </Col>
                      </>
                    ))
                  )}
                </Row>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default withRouter(SearchResults);
