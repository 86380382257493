import {
  USER_SIGNUP_LOADING,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_SUCCESS,
  USER_LOGIN_LOADING,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_DATA_LOADING,
  USER_DATA_FAIL,
  USER_DATA_SUCCESS,
  AUTH_LOADING,
  AUTH_FAIL,
  AUTH_SUCCESS,
} from "../types/userTypes";
import { axiosClient } from "../../helper";
//////////////////////////////////////////////////////////////////////
export const UserSignUpLoading = () => ({
  type: USER_SIGNUP_LOADING,
});
export const UserSignUpSuccess = (payload) => ({
  type: USER_SIGNUP_SUCCESS,
  payload,
});
export const UserSignUpFail = () => ({
  type: USER_SIGNUP_FAIL,
});
export const UserLoginLoading = () => ({
  type: USER_LOGIN_LOADING,
});
export const UserLoginSuccess = (payload) => ({
  type: USER_LOGIN_SUCCESS,
  payload,
});
export const UserLoginFail = () => ({
  type: USER_LOGIN_FAIL,
});
export const UserDataLoading = () => ({
  type: USER_DATA_LOADING,
});
export const UserDataSuccess = (payload) => ({
  type: USER_DATA_SUCCESS,
  payload,
});
export const UserDataFail = () => ({
  type: AUTH_FAIL,
});
export const AuthLoading = () => ({
  type: AUTH_LOADING,
});
export const AuthSuccess = (payload) => ({
  type: AUTH_SUCCESS,
  payload,
});
export const AuthFail = () => ({
  type: USER_DATA_FAIL,
});
//////////////////////////////////////////////////////////////////////
export const UserSignUp = (formData) => async (dispatch) => {
  dispatch(UserSignUpLoading());
  try {
    const result = await axiosClient().post(`/signupBuyer`, formData);
    if (result) {
      dispatch(UserSignUpSuccess(result));
    }
    return result;
  } catch (err) {
    dispatch(UserSignUpFail(err));
    return err.response;
  }
};
export const UserLogin = (formData) => async (dispatch) => {
  dispatch(UserLoginLoading());
  try {
    const result = await axiosClient().post(`/buyerLogin`, formData);
    if (result) {
      dispatch(UserLoginSuccess(result));
    }
    return result;
  } catch (err) {
    dispatch(UserLoginFail(err.response));
    return err.response;
  }
};
export const GetUserDataByID = () => async (dispatch) => {
  dispatch(UserDataLoading());
  try {
    const result = await axiosClient().get(
      `/getUserById/${localStorage.getItem("_id")}`
    );
    if (result) {
      dispatch(UserDataSuccess(result));
    }
    return result;
  } catch (err) {
    dispatch(UserDataFail(err.response));
    return err.response;
  }
};
export const Auth = () => async (dispatch) => {
  dispatch(AuthLoading());
  try {
    const result = await axiosClient().post(`/auth`);
    if (result) {
      dispatch(AuthSuccess(result));
    }
    return result;
  } catch (err) {
    dispatch(AuthFail(err.response));
    return err.response;
  }
};
