import {
  CATEGORIES_LOADING,
  CATEGORIES_FAIL,
  CATEGORIES_SUCCESS,
} from "../types/categoriesTypes";
import { axiosClient } from "../../helper";
//////////////////////////////////////////////////////////////////////
export const CategoriesLoading = () => ({
  type: CATEGORIES_LOADING,
});
export const CategoriesSuccess = (payload) => ({
  type: CATEGORIES_SUCCESS,
  payload,
});
export const CategoriesFail = () => ({
  type: CATEGORIES_FAIL,
});
//////////////////////////////////////////////////////////////////////
export const getCategories = () => async (dispatch) => {
  dispatch(CategoriesLoading());
  try {
    const result = await axiosClient().get(`/getCategories`);

    if (result) {
      dispatch(CategoriesSuccess(result.data));
    }
    return result;
  } catch (err) {
    dispatch(CategoriesFail(err.response));
    return err.response;
  }
};
