import { combineReducers } from "redux";
import userReducer from "./userReducer";
import categoriesReducer from "./categoriesReducer";
import wishListReducer from "./wishListReducer";
import basketReducer from "./basketReducer";
import generalReducer from "./generalReducer";
import productReducer from "./productReducer";
const RootReducer = combineReducers({
  userReducer,
  categoriesReducer,
  wishListReducer,
  generalReducer,
  basketReducer,
  productReducer,
});

export default RootReducer;
