import {
  GET_ALL_CITIES_LOADING,
  GET_ALL_CITIES_SUCCESS,
  GET_ALL_CITIES_FAILED,
  GET_ALL_TAGES_LOADING,
  GET_ALL_TAGES_SUCCESS,
  GET_ALL_TAGES_FAILED,
  CHANGE_LOCATION,
} from "../types/generalTypes";

const initialState = {
  loading: true,
  allCities: [],
  allTages: [],
  message: "",
  choosedLocation: "Islamabad",
  selectedCityCode: "ISB",
  selectedCityRegion: "Islamabad",
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CITIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allCities: action.payload,
        message: action.payload.message,
      };
    case GET_ALL_CITIES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_LOCATION:
      return {
        ...state,
        choosedLocation: action.item.choosedLocation,
        selectedCityCode: action.item.selectedCityCode,
        selectedCityRegion: action.item.selectedCityRegion,
      };

    case GET_ALL_TAGES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_TAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        allTages: action.payload,
      };
    case GET_ALL_TAGES_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
