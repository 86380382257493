import {
  GET_ALL_CITIES_LOADING,
  GET_ALL_CITIES_SUCCESS,
  GET_ALL_CITIES_FAILED,
  GET_ALL_TAGES_LOADING,
  GET_ALL_TAGES_SUCCESS,
  GET_ALL_TAGES_FAILED,
  CHANGE_LOCATION,
} from "../types/generalTypes";

import { axiosClient } from "../../helper";

export const CitiesLoading = () => ({
  type: GET_ALL_CITIES_LOADING,
});
export const CitiesSuccess = (payload) => ({
  type: GET_ALL_CITIES_SUCCESS,
  payload,
});
export const CitiesFail = () => ({
  type: GET_ALL_CITIES_FAILED,
});

export const getAllCities = () => async (dispatch) => {
  dispatch(CitiesLoading());
  try {
    const result = await axiosClient().get(`/getAllCities`);
    console.log("result", result);
    if (result) {
      dispatch(CitiesSuccess(result?.data?.data));
      dispatch(getCurrentLocation(result?.data?.data));
    }
    return result;
  } catch (err) {
    dispatch(CitiesFail(err.response));
    return err.response;
  }
};
export const getCurrentLocation = (cities) => async (dispatch) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiKey = "AIzaSyAs0czNpJJkwwOjvlswNkQKDI6in_1CN4k";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data.results.length > 0) {
              for (
                var i = 0;
                i < data.results[0].address_components.length;
                i++
              ) {
                var types = data.results[0].address_components[i].types;

                for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
                  if (
                    types[typeIdx] == "locality" ||
                    types[typeIdx] == "administrative_area_level_2"
                  ) {
                    let shortCode;

                    shortCode = cities?.filter(
                      (x) =>
                        x?.cityName?.toLowerCase() ===
                        data.results[0].address_components[
                          i
                        ].short_name?.toLowerCase()
                    );
                    console.log("shortCode[0]", shortCode[0]);
                    dispatch(ChangeLocation(shortCode[0]));
                  }
                }
              }
            } else {
              console.log("No results found for the given coordinates");
            }
          })
          .catch((error) => {
            console.log("Error fetching geocoding data:", error);
          });
      },
      (error) => {
        console.log("Error getting geolocation:", error.message);
      }
    );
  } else {
    console.log("Geolocation is not supported by your browser");
  }
};
export const ChangeLocation = (shortCode) => {
  console.log("shortCode", shortCode);
  return {
    type: CHANGE_LOCATION,
    item: {
      choosedLocation: shortCode?.cityName,
      selectedCityCode: shortCode?.cityCode,
      selectedCityRegion: shortCode?.provinceId?.provinceName,
    },
  };
};
// export const ChangeLocation = (shortCode) => async (dispatch) => ({
//   type: CHANGE_LOCATION,
//   item: {
//     choosedLocation: shortCode?.cityName,
//     selectedCityCode: shortCode?.cityCode,
//     selectedCityRegion: shortCode?.provinceId,
//   },
// });

export const TagesLoading = () => ({
  type: GET_ALL_TAGES_LOADING,
});
export const TagesSuccess = (payload) => ({
  type: GET_ALL_TAGES_SUCCESS,
  payload,
});
export const TagesFail = () => ({
  type: GET_ALL_TAGES_FAILED,
});

export const getAllTages = () => async (dispatch) => {
  dispatch(TagesLoading());
  try {
    const response = await axiosClient().get(`/getAllTagNames`);

    if (response.status === 200) {
      const pokemon = [];
      response.data.tags &&
        response.data.tags.map((value) =>
          pokemon.push(value.name.toLowerCase())
        );

      dispatch(TagesSuccess(pokemon));
    }
    return response;
  } catch (err) {
    dispatch(TagesFail(err.response));
    return err.response;
  }
};
