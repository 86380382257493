import React, { useState, useEffect } from "react";
import { Carousel, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useStateValue } from "../../StateProvider";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import "./NewHome.css";
import { axiosClient } from "../../helper";
import banner4 from "../../assets/banners/Banner3.png";
import service1 from "../../assets/services1.png";
import service2 from "../../assets/services2.png";
import service3 from "../../assets/services2.png";
import product from "../../assets/product.png";
import Wishlist from "../../assets/Wishlist.png";
import ShoppingBag from "../../assets/Shopping Bag.png";
var settings = {
  dots: false,
  infinite: true,
  // centerMode: true,
  // speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  // nextArrow: <ChevronRightIcon />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};

function NewHome(props) {
  const [{ choosedLocation }] = useStateValue();
  const [category, setCategory] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);

  useEffect(() => {
    getCategory();
    getProducts();
  }, [choosedLocation]);

  const getProducts = () => {
    axiosClient()
      .get(`/getFeaturedProducts/${choosedLocation}`)
      .then((res) => {
        setFeaturedProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosClient()
      .get("/getTopSellingProducts")
      .then((res) => {
        setTopSellingProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategory = () => {
    axiosClient()
      .get("/getCategories")
      .then((response) => {
        if (response.status === 200) {
          setCategory(response.data.categories);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  return (
    <>
      <Helmet>
        <title>Iown store</title>
        <meta name="Iown store" content="Iown store Home Page" />
      </Helmet>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Carousel>
            <Carousel.Item>
              <div class="carousel-container">
                <img className="d-block w-100" src={banner4} alt="iBuild" />
                <div className="carousel-center">
                  <div className="carousel-center-text">
                    <h3>Lets Build Your</h3>
                    <h2>Dream Together</h2>
                  </div>
                  <div className="carousel-center-btn">
                    <Button
                      variant="light"
                      style={{
                        width: "200px",
                        borderRadius: "none",
                        padding: "17px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Contuct Us Now
                    </Button>
                    <Button
                      variant="outline-light"
                      style={{
                        width: "200px",
                        borderRadius: "none",
                        padding: "16px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <section className="home__ourSevices">
          <div className="home__ourSevices-shop-card">
            <div className="home__ourSevices-shop-card-heading">
              <div className="home__ourSevices-shop-card-heading-left">
                <h3>Our Sevices</h3>
                <div className="home__ourSevices-shop-card-heading-left-border"></div>
              </div>
              <div className="home__ourSevices-shop-card-heading-right">
                <div className="home__ourSevices-shop-card-heading-right-leftIcon">
                  <FaAngleLeft
                    style={{ color: "#fff", width: "25px", height: "25px" }}
                  />
                </div>
                <div className="home__ourSevices-shop-card-heading-right-rightIcon">
                  <FaAngleRight
                    style={{ color: "#000", width: "25px", height: "25px" }}
                  />
                </div>
              </div>
            </div>

            <div container className="home__ourSevices-shop-card-row-img">
              <div className="home__ourSevices-shop-card-img">
                <img src={service1} alt="iBuild" />
                <div className="home__ourSevices-shop-card-col-img">
                  <div className="home__ourSevices-shop-card-col-img-text">
                    <h3>Kitchen</h3>
                    <h3>Fitting</h3>
                  </div>
                  <div className="home__ourSevices-shop-card-col-img-btn">
                    <Button
                      variant="outline-light"
                      style={{
                        width: "180px",
                        borderRadius: "none",
                        padding: "16px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                    </Button>
                  </div>
                </div>
              </div>
              <div className="home__ourSevices-shop-card-img">
                <img src={service2} alt="iBuild" />
                <div className="home__ourSevices-shop-card-col-img">
                  <div className="home__ourSevices-shop-card-col-img-text">
                    <h3>Sanitary</h3>
                    <h3>Fitting</h3>
                  </div>
                  <div className="home__ourSevices-shop-card-col-img-btn">
                    <Button
                      variant="outline-light"
                      style={{
                        width: "180px",
                        borderRadius: "none",
                        padding: "16px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                    </Button>
                  </div>
                </div>
              </div>
              <div className="home__ourSevices-shop-card-img">
                <img src={service3} alt="iBuild" />
                <div className="home__ourSevices-shop-card-col-img">
                  <div className="home__ourSevices-shop-card-col-img-text">
                    <h3>Wood &</h3>
                    <h3>Glass</h3>
                  </div>
                  <div className="home__ourSevices-shop-card-col-img-btn">
                    <Button
                      variant="outline-light"
                      style={{
                        width: "180px",
                        borderRadius: "none",
                        padding: "16px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home__feadured">
          <div className="home__feadured-shop-card">
            <div className="home__feadured-shop-card-heading">
              <div className="home__feadured-shop-card-heading-left">
                <h3>Featured Products</h3>
                <div className="home__feadured-shop-card-heading-left-border"></div>
              </div>
              <div className="home__feadured-shop-card-heading-right">
                <div className="home__feadured-shop-card-heading-right-leftIcon">
                  <FaAngleLeft
                    style={{ color: "#fff", width: "25px", height: "25px" }}
                  />
                </div>
                <div className="home__feadured-shop-card-heading-right-rightIcon">
                  <FaAngleRight
                    style={{ color: "#000", width: "25px", height: "25px" }}
                  />
                </div>
              </div>
            </div>
            <div container className="home__feadured-shop-card-row">
              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>

              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>
              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home__feadured">
          <div className="home__feadured-shop-card">
            <div className="home__feadured-shop-card-heading">
              <div className="home__feadured-shop-card-heading-left">
                <h3>Recently Viewed</h3>
                <div className="home__feadured-shop-card-heading-left-border"></div>
              </div>
              <div className="home__feadured-shop-card-heading-right">
                <div className="home__feadured-shop-card-heading-right-leftIcon">
                  <FaAngleLeft
                    style={{ color: "#fff", width: "25px", height: "25px" }}
                  />
                </div>
                <div className="home__feadured-shop-card-heading-right-rightIcon">
                  <FaAngleRight
                    style={{ color: "#000", width: "25px", height: "25px" }}
                  />
                </div>
              </div>
            </div>
            <div container className="home__feadured-shop-card-row">
              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>

              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>
              <div className="home__feadured-shop-card">
                <div className="home__feadured-shop-card-top">
                  <div className="home__feadured-shop-card-top-icon">
                    <div className="wishlist">
                      <img
                        src={Wishlist}
                        style={{ width: "25px", height: "25px" }}
                        alt="Wishlist"
                      />
                    </div>
                    <div className="shoppingBag">
                      <img
                        src={ShoppingBag}
                        style={{ width: "25px", height: "25px" }}
                        alt="ShoppingBag"
                      />
                    </div>
                  </div>
                  <div className="home__feadured-shop-card-top-img">
                    <img src={product} alt="iBuild" />
                  </div>
                </div>
                <div className="home__feadured-shop-card-bottom">
                  <div className="home__feadured-shop-card-bottom-text">
                    <h3>Name Here</h3>
                  </div>
                  <div className="home__feadured-shop-card-bottom-icon">
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />

                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                    <IoMdStar
                      style={{
                        width: "30px",
                        height: "25px",
                        color: "rgb(32, 129, 83)",
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <h3>Rs 100</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default NewHome;
