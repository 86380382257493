import React, { useEffect } from "react";
import { Grid, Typography, Box, Container } from "@material-ui/core";
import { FaFacebookF, FaTwitter, FaPhoneAlt, FaGoogle } from "react-icons/fa";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Img from "../../assets/iown.png";
import "./style.css";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
const Index = () => {
  const IconsArray = [
    {
      icon: FaFacebookF,
      link: "https://www.facebook.com",
    },
    {
      icon: FaTwitter,
      link: "https://www.twitter.com",
    },
    {
      icon: FaPhoneAlt,
      link: "tel:+923234836348",
    },
    {
      icon: FaGoogle,
      link: "https://www.google.com",
    },
  ];
  const handleSocialPages = (link) => {
    window.open(link);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="Footer">
      <a onClick={handleClick}>
        <div className="Footer__Back-To-Top">
          <ExpandLessIcon className="Footer__Back-To-Top-Text" />
          {/* <span className="Footer__Back-To-Top-Text"y/> */}
        </div>
      </a>
      <Grid container item xs={12} className="footer-main-grid">
        <Grid container xs={12} className="wrapper footer-all-grids-container">
          <Grid item xs={12} md={4} className="footer-description">
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img src={Img} className="footer-img" />
              <Typography className="footer-iOwn-text">iOwn.store</Typography>
            </Box>
            <Typography className="footer-description-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dumm
            </Typography>
            <Typography className="footer-followUs">Follow Us</Typography>
            <Grid style={{ display: "flex" }}>
              {IconsArray?.map((x) => (
                <Box
                  className="footer-icons-box"
                  onClick={() => handleSocialPages(x?.link)}
                >
                  <x.icon className="footer-icon" />
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4} md={2} className="footer-links-grid">
            <Typography className="footer-links-headings">
              Get to Know Us
            </Typography>
            <Typography className="footer-links">About</Typography>
            <Typography className="footer-links">Career</Typography>
            <Typography className="footer-links">Press</Typography>
            <Typography className="footer-links">Iown store Cares</Typography>
            <Typography className="footer-links">Gift a smile</Typography>
          </Grid>
          <Grid item xs={4} md={3} className="footer-links-grid">
            <Typography className="footer-links-headings">
              Make Money with Us
            </Typography>
            <Typography className="footer-links">Sell on Iown store</Typography>
            <Typography className="footer-links">
              Sell under Iown store
            </Typography>
            <Typography className="footer-links">
              Become an Affiliate
            </Typography>
            <Typography className="footer-links">
              Fulfilment by Iown store
            </Typography>
            <Typography className="footer-links">Iown store Pay</Typography>
          </Grid>
          <Grid item xs={4} md={3} className="footer-links-grid">
            <Typography className="footer-links-headings">
              Connect with us
            </Typography>
            <Typography className="footer-links">
              COVID-19 and Iown store
            </Typography>
            <Typography className="footer-links">Your Account</Typography>
            <Typography className="footer-links">Returns Centre</Typography>
            <Typography className="footer-links">
              100% Purchase Protection
            </Typography>
            <Typography className="footer-links">Help</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="chatNow-Grid">
          <Grid className="chat-now-grid">
            <PersonOutlineIcon className="footer-person-icon" />
            <Typography className="footer-chatnow-text">
              Have A Question ? <br /> <b>CHAT NOW</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid className="all-rights-reserved">
          <Typography className="footer-wrapper">
            All Rights Reserved By <b>iOwn.pk</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default Index;
