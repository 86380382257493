import {
  CATEGORIES_LOADING,
  CATEGORIES_FAIL,
  CATEGORIES_SUCCESS,
} from "../types/categoriesTypes";

const initialState = {
  loading: true,
  categories: [],
  message: "",
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        message: action.payload.message,
      };
    case CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
