import {
  ADD_TO_WISHLIST_LOADING,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAIL,
} from "../types/wishListTypes";

const initialState = {
  loading: true,
  wishList: {},
  message: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_WISHLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishList: action.payload,
        message: action.payload.message,
      };
    case ADD_TO_WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
