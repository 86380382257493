import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, Container } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Table from "@material-ui/core/Table";
// import TableHead from "@material-ui/core/TableHead";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableRow from "@material-ui/core/TableRow";
import { Form, Button } from "react-bootstrap";
import { HiShoppingCart } from "react-icons/hi";
import { Card } from "react-bootstrap";
import { AiFillPlaySquare } from "react-icons/ai";
import { useParams, useHistory } from "react-router-dom";
import { SideBySideMagnifier } from "react-image-magnifiers";
import { ToastContainer, toast } from "react-toastify";
import Slider from "react-slick";
import axios from "axios";
import ProductDetail from "./productdetail";
import ProductInfo from "./productInfo";
import { axiosClient } from "../../helper";
import { useStateValue } from "../../StateProvider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./ProductDetails.css";
import {
  addToBaskets,
  addShipping,
  updateShipping,
  updateBasket,
  updateCheckoutQuantity,
} from "../../redux/actions/basketAction";
import ProductsCard from "../../components/ProductsCard";
import { addProductToRecientView } from "../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "justify",
    color: theme.palette.text.secondary,
    lineHeight: 0.8,
    marginLeft: "10px",
  },
  heading: {
    fontSize: "24px !important",
    lineHeight: "32px !important",
  },
  p: {
    color: "black",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
}));

const Products = (props) => {
  let updatedPrice;
  const dispatch = useDispatch();
  const { basket } = useSelector((state) => state.basketReducer);
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const history = useHistory();
  const prdctId = useParams().productId;
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState("");
  // const [prevQuantity, setPrevQuantity] = useState(0);
  const [maximumQuantity, setMaximumQuantity] = useState();

  const [minimumQuantity, setMinimumQuantity] = useState();
  const classes = useStyles();
  const [image, setImage] = useState();
  const [loader, setLoader] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [sliderProducts, setSliderProducts] = useState([]);
  const [array, setArray] = useState([]);
  const [location, setLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const sliderRef3 = useRef();
  // const [deliveryCities, setDeliveryCities] = useState([]);
  let locationTransportCharges = 0;
  let orderDeliveryTime = {};
  let transCharges = "";
  let isDelivery = false;
  console.log("sliderProducts", sliderProducts);
  var settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let isInBasket = [];
  let initialShippingCharges = 0;

  const handleChange = (imgPath) => {
    setImage(imgPath);
  };

  const handlelocation = (product) => {
    axios
      .get("https://geolocation-db.com/json/geoip.php?json=callback")
      .then((response) => {
        setCurrentLocation(response?.data.city);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axiosClient()
      .get(`/getProductById/${prdctId}`)
      .then((res) => {
        setLoader(false);
        setProduct([res.data.product]);
        console.log("res.data.product", res.data.product);

        setImage(res.data.product?.image[0]);
        getProductsByCategoryId(res.data.product.categoryId._id);
        setMaximumQuantity(res.data.product.maximumQuantity);
        setQuantity(res.data.product.minimumQuantity);

        setMinimumQuantity(res.data.product.minimumQuantity);

        res.data.product.isMultipleAllow &&
          dropdownValues(
            res.data.product.minimumQuantity,
            res.data.product.chooseMultiple,
            res.data.product.maximumQuantity
          );
        handlelocation(res.data.product);

        setLocation(choosedLocation);
        dispatch(addProductToRecientView([res.data.product]));
      })
      .catch((err) => {
        setLoader(false);
      });
  }, [prdctId, choosedLocation]);

  const dropdownValues = (min, chooseMultiple, max) => {
    let calculateQuantity = [];
    let previouse = min * chooseMultiple;

    for (let i = min * chooseMultiple; i < max; i += min * chooseMultiple) {
      if (
        previouse + min * chooseMultiple < max ||
        previouse + min * chooseMultiple === max
      ) {
        calculateQuantity.push(previouse + min * chooseMultiple);
        setArray(calculateQuantity);
      }

      previouse = previouse + min * chooseMultiple;
    }
  };

  const calCommission = () => {
    let commissionAmount = 0;
    if (product[0].isFixed || product[0].isPercentage) {
      commissionAmount = parseFloat(
        (updatedPrice - product[0].price).toFixed(2)
      );
    } else {
      commissionAmount = 0;
    }
    return commissionAmount;
  };

  const addToBasket = () => {
    // Dispatch the item into the data layer
    const amt = calCommission();
    console.log("amt: ", amt);
    isInBasket = basket.findIndex((b) => b.id === product[0]._id);

    if (quantity < minimumQuantity) {
      toast.error(`Quantity cannot be less than ${minimumQuantity}, try again`);
      setQuantity(minimumQuantity);
    } else if (maximumQuantity > 0 && quantity > maximumQuantity) {
      toast.error(
        `Quantity cannot be greater than ${maximumQuantity}, try again`
      );
      setQuantity(maximumQuantity);
    } else {
      if (isInBasket > -1) {
        dispatch(
          updateCheckoutQuantity({
            id: product[0]._id,
            quantity: quantity,
          })
        );
        // dispatch({
        //   type: "UPDATE_CHECKOUT_QUANTITY",
        //   id: product[0]._id,
        //   quantity: quantity,
        // });
        dispatch(
          updateBasket({
            item: {
              id: product[0]._id,
              title: product[0].name,
              brand: product[0].brand,
              image: product[0].image[0],
              // price: product[0].price,
              initialCommission: amt,
              adminCommissionAmount: amt ? amt * quantity : 0,

              category: product[0].categoryId.name,
              price: updatedPrice,
              priceUnit: product[0].priceUnit,
              // rating: product[0].averageRating,
              quantity: quantity,
              minimumQuantity: product[0].minimumQuantity,
              maximumQuantity: product[0].maximumQuantity,
              sellerId: product[0].userID._id,
              // shippingCharges: product[0].shippingCharges,
              shippingCharges: parseInt(locationTransportCharges),
              isMultipleAllow: product[0].isMultipleAllow,
              chooseMultiple: product[0].chooseMultiple,
              array: array,
              estimatedDeliveryTime: orderDeliveryTime,
              deliveryTimeline: product[0].deliveryTimeLine,
              maxTransportationVolume: parseInt(
                product[0].maxTransportationVolume
              ),
              initialShippingCharges: initialShippingCharges,
              // deliverableCities: deliveryCities,
            },
          })
        );
        // dispatch({
        //   type: "UPDATE_BASKET",
        //   item: {
        //     id: product[0]._id,
        //     title: product[0].name,
        //     brand: product[0].brand,
        //     image: product[0].image[0],
        //     // price: product[0].price,
        //     initialCommission: amt,
        //     adminCommissionAmount: amt ? amt * quantity : 0,

        //     category: product[0].categoryId.name,
        //     price: updatedPrice,
        //     // rating: product[0].averageRating,
        //     quantity: quantity,
        //     minimumQuantity: product[0].minimumQuantity,
        //     maximumQuantity: product[0].maximumQuantity,
        //     sellerId: product[0].userID._id,
        //     // shippingCharges: product[0].shippingCharges,
        //     shippingCharges: parseInt(locationTransportCharges),
        //     isMultipleAllow: product[0].isMultipleAllow,
        //     chooseMultiple: product[0].chooseMultiple,
        //     array: array,
        //     estimatedDeliveryTime: orderDeliveryTime,
        //     deliveryTimeline: product[0].deliveryTimeLine,
        //     maxTransportationVolume: parseInt(
        //       product[0].maxTransportationVolume
        //     ),
        //     initialShippingCharges: initialShippingCharges,
        //     // deliverableCities: deliveryCities,
        //   },
        // });
        dispatch(
          updateShipping({
            id: product[0]._id,
            item: {
              shippingCharges: parseInt(locationTransportCharges),
            },
          })
        );
        // dispatch({
        //   type: "UPDATE_SHIPPING",
        //   id: product[0]._id,
        //   item: {
        //     shippingCharges: parseInt(locationTransportCharges),
        //   },
        // });
        toast.success("Cart updated");
      } else {
        dispatch(
          addToBaskets({
            item: {
              id: product[0]._id,
              title: product[0].name,
              brand: product[0].brand,
              image: product[0].image[0],
              // price: product[0].price,
              initialCommission: amt,
              adminCommissionAmount: amt ? amt * quantity : 0,
              category: product[0].categoryId.name,
              price: updatedPrice,
              priceUnit: product[0].priceUnit,
              // rating: product[0].averageRating,
              quantity: quantity,
              minimumQuantity: product[0].minimumQuantity,
              maximumQuantity: product[0].maximumQuantity,
              sellerId: product[0].userID._id,
              // shippingCharges: product[0].shippingCharges,
              shippingCharges: parseInt(locationTransportCharges),
              isMultipleAllow: product[0].isMultipleAllow,
              chooseMultiple: product[0].chooseMultiple,
              array: array,
              estimatedDeliveryTime: orderDeliveryTime,
              deliveryTimeline: product[0].deliveryTimeLine,
              maxTransportationVolume: parseInt(
                product[0].maxTransportationVolume
              ),
              initialShippingCharges: initialShippingCharges,
            },
          })
        );
        // dispatch({
        //   type: "ADD_TO_BASKET",
        //   item: {
        //     id: product[0]._id,
        //     title: product[0].name,
        //     brand: product[0].brand,
        //     image: product[0].image[0],
        //     // price: product[0].price,
        //     initialCommission: amt,
        //     adminCommissionAmount: amt ? amt * quantity : 0,
        //     category: product[0].categoryId.name,
        //     price: updatedPrice,
        //     // rating: product[0].averageRating,
        //     quantity: quantity,
        //     minimumQuantity: product[0].minimumQuantity,
        //     maximumQuantity: product[0].maximumQuantity,
        //     sellerId: product[0].userID._id,
        //     // shippingCharges: product[0].shippingCharges,
        //     shippingCharges: parseInt(locationTransportCharges),
        //     isMultipleAllow: product[0].isMultipleAllow,
        //     chooseMultiple: product[0].chooseMultiple,
        //     array: array,
        //     estimatedDeliveryTime: orderDeliveryTime,
        //     deliveryTimeline: product[0].deliveryTimeLine,
        //     maxTransportationVolume: parseInt(
        //       product[0].maxTransportationVolume
        //     ),
        //     initialShippingCharges: initialShippingCharges,
        //     // deliverableCities: deliveryCities,
        //   },
        // });
        dispatch(
          addShipping({
            item: {
              shippingCharges: parseInt(locationTransportCharges),
            },
          })
        );
        // dispatch({
        //   type: "ADD_SHIPPING",

        //   item: {
        //     shippingCharges: parseInt(locationTransportCharges),
        //   },
        // });
        toast.success("Added to cart");
      }
    }
  };

  const calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (updatedPrice =
        (parseInt(price) / 100) * parseInt(commissionAmount) + parseInt(price));
    } else if (isFixed) {
      return (updatedPrice = parseInt(price) + parseInt(commissionAmount));
    } else {
      return (updatedPrice = parseInt(price));
    }
  };

  const handleBuyNow = () => {
    if (quantity < minimumQuantity) {
      toast.error(`Quantity cannot be less than ${minimumQuantity}, try again`);
      setQuantity(minimumQuantity);
    } else if (maximumQuantity > 0 && quantity > maximumQuantity) {
      toast.error(
        `Quantity cannot be greater than ${maximumQuantity}, try again`
      );
      setQuantity(maximumQuantity);
    } else {
      addToBasket();
      history.push("/checkout");
    }
  };

  const addToList = () => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined
    ) {
      return history.push("/login");
    }
    if (isLiked) {
      toast.info("Already added to wishlist");
    } else {
      axiosClient()
        .post("/addProductToWishList", { productId: product[0]._id })
        .then((res) => {
          toast.success("Added to wishlist");
          setIsLiked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProductsByCategoryId = (categoryId) => {
    axiosClient()
      .get(`/admin/getProductsByCategoryId/${categoryId}/1/${choosedLocation}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.products.length <= 0) {
            setSliderProducts([]);
          } else {
            setSliderProducts(response.data.products);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleQuantityUpdate = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const onBlurQuantityUpdate = (e, minQuantity, maxQuantity) => {
    if (e.target.value < minQuantity || e.target.value <= 0) {
      setQuantity(minQuantity);
      toast.error(`Minimum quantity should be ${minQuantity}`);
    } else if (e.target.value > maxQuantity) {
      setQuantity(maxQuantity);
      toast.error(`Maximum quantity should be ${maxQuantity}`);
    }
  };

  const handleDisplayTransportationCharges = (
    transportData,
    deliveryData,
    locationByCity,
    maxQuantityVolum
  ) => {
    let val;
    let val2;

    let quotient;
    let remainder;

    for (var i = 0; i < transportData?.length; i++) {
      if (product[0].transportationCharges) {
        quotient = Math.floor(quantity / maxQuantityVolum);
        remainder = quantity % maxQuantityVolum;
      } else {
        quotient = "";
        remainder = "";
      }

      if (
        transportData[i].transportLocatiion &&
        location &&
        transportData[i].transportLocatiion?.toLowerCase() ===
          location?.toLowerCase()
      ) {
        if (quotient === 0) {
          val = `Delivery at PKR +${transportData[i].transportCharges}  for ${location}`;
          locationTransportCharges = transportData[i].transportCharges;
          initialShippingCharges = transportData[i].transportCharges;
          isDelivery = true;
          i = transportData.length;
        } else if (quotient === 1 && remainder === 0) {
          val = `Delivery at PKR +${transportData[i].transportCharges}  for ${location}`;
          locationTransportCharges = transportData[i].transportCharges;
          initialShippingCharges = transportData[i].transportCharges;
          isDelivery = true;
          i = transportData.length;
        } else if (quotient > 1 && remainder === 0) {
          val = `Delivery at PKR +${
            transportData[i].transportCharges * quotient
          }  for ${location}`;
          locationTransportCharges =
            transportData[i].transportCharges * quotient;
          initialShippingCharges = transportData[i].transportCharges;
          isDelivery = true;
          i = transportData.length;
        } else {
          quotient = quotient + 1;
          val = `Delivery at PKR +${
            transportData[i].transportCharges * quotient
          }  for ${location}`;
          locationTransportCharges =
            transportData[i].transportCharges * quotient;
          initialShippingCharges = transportData[i].transportCharges;
          isDelivery = true;
          i = transportData.length;
        }
      } else if (
        transportData[i].transportLocatiion?.toLowerCase() !==
          location?.toLowerCase() &&
        locationByCity[0] === "All"
      ) {
        if (
          transportData[i].transportLocatiion?.toLowerCase() ===
            currentLocation?.toLowerCase() &&
          choosedLocation === "All"
        ) {
          if (quotient === 0) {
            val = `Delivery at PKR +${transportData[i].transportCharges}  for ${currentLocation}`;
            locationTransportCharges = transportData[i].transportCharges;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else if (quotient === 1 && remainder === 0) {
            val = `Delivery at PKR +${transportData[i].transportCharges}  for ${currentLocation}`;
            locationTransportCharges = transportData[i].transportCharges;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else if (quotient > 1 && remainder === 0) {
            val = `Delivery at PKR +${
              transportData[i].transportCharges * quotient
            }  for ${currentLocation}`;
            locationTransportCharges =
              transportData[i].transportCharges * quotient;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else {
            quotient = quotient + 1;
            val = `Delivery at PKR +${
              transportData[i].transportCharges * quotient
            }  for ${currentLocation}`;
            locationTransportCharges =
              transportData[i].transportCharges * quotient;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          }
          i = transportData.length;
        } else {
          isDelivery = true;
          val = `Delivery at PKR +0.00  for ${
            choosedLocation === "All" ? currentLocation : location
          }`;
        }
      } else if (
        transportData[i].transportLocatiion?.toLowerCase() !==
          location.toLowerCase() &&
        locationByCity[0] !== "All"
      ) {
        if (
          transportData[i].transportLocatiion?.toLowerCase() ===
            currentLocation?.toLowerCase() &&
          choosedLocation === "All"
        ) {
          if (quotient === 0) {
            val = `Delivery at PKR +${transportData[i].transportCharges}  for ${currentLocation}`;
            locationTransportCharges = transportData[i].transportCharges;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else if (quotient === 1 && remainder === 0) {
            val = `Delivery at PKR +${transportData[i].transportCharges}  for ${currentLocation}`;
            locationTransportCharges = transportData[i].transportCharges;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else if (quotient > 1 && remainder === 0) {
            val = `Delivery at PKR +${
              transportData[i].transportCharges * quotient
            }  for ${currentLocation}`;
            locationTransportCharges =
              transportData[i].transportCharges * quotient;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          } else {
            quotient = quotient + 1;
            val = `Delivery at PKR +${
              transportData[i].transportCharges * quotient
            }  for ${currentLocation}`;
            locationTransportCharges =
              transportData[i].transportCharges * quotient;
            initialShippingCharges = transportData[i].transportCharges;
            isDelivery = true;
          }
          i = transportData.length;
        } else if (
          transportData[i].transportLocatiion?.toLowerCase() !==
            currentLocation?.toLowerCase() &&
          choosedLocation === "All"
        ) {
          isDelivery = false;
          val = `Delivery not available for ${currentLocation}`;
        } else if (
          transportData[i].transportLocatiion !== location &&
          !product[0].transportCharges
          //   ||
          // (transportData[i].transportLocatiion !== location &&
          //   choosedLocation !== "All")
        ) {
          isDelivery = false;
          val = `Delivery not available for ${location}`;
        } else {
          isDelivery = true;
          val = `Delivery at PKR +0.00  for ${
            choosedLocation === "All" ? currentLocation : location
          }`;
        }
      } else {
        isDelivery = false;
        val = `Delivery not available for ${
          choosedLocation === "All" ? currentLocation : location
        }`;
      }
    }

    for (var i = 0; i < deliveryData?.length; i++) {
      if (
        deliveryData[i].deliveryLocation?.toLowerCase() ===
        location?.toLowerCase()
      ) {
        val2 = `  within ${deliveryData[i].deliveryTime} ${deliveryData[
          i
        ].deliveryTimeUnit?.toLowerCase()}(s)`;
        orderDeliveryTime = deliveryData[i];
        i = deliveryData.length;
      } else if (
        deliveryData[i].deliveryLocation !== location &&
        locationByCity[0] === "All" &&
        choosedLocation === "All"
      ) {
        if (
          transportData[i]?.transportLocatiion?.toLowerCase() ===
          currentLocation?.toLowerCase()
        ) {
          val2 = `  within ${deliveryData[i]?.deliveryTime} ${deliveryData[
            i
          ]?.deliveryTimeUnit?.toLowerCase()}(s)`;
          orderDeliveryTime = deliveryData[i];
          i = deliveryData.length;
        } else {
          val2 = "";
        }
        // i = deliveryData.length;
      } else if (
        deliveryData[i].deliveryLocation?.toLowerCase() !==
          location?.toLowerCase() &&
        locationByCity[0] !== "All" &&
        choosedLocation === "All"
      ) {
        if (
          transportData[i]?.transportLocatiion?.toLowerCase() ===
          currentLocation?.toLowerCase()
        ) {
          val2 = `  within ${deliveryData[i]?.deliveryTime} ${deliveryData[
            i
          ]?.deliveryTimeUnit?.toLowerCase()}(s)`;
          orderDeliveryTime = deliveryData[i];
          i = deliveryData.length;
        } else {
          val2 = "";
        }
        // i = deliveryData.length;
      } else {
        val2 = "";
      }
    }

    transCharges = (
      <span>
        {val} <br /> {val2}
      </span>
    );
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {loader ? (
        <div className="loaders">
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          {product.map((item, k) => {
            return (
              <React.Fragment key={k}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: "26px 10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={1}
                    className="sub_magnify_images"
                  >
                    <Paper className={classes.paper}>
                      {item.image.map((imgg, imgkey) => {
                        return (
                          <div className="side-images-div" key={imgkey}>
                            <img
                              className="side-images"
                              onMouseOver={() => handleChange(imgg)}
                              src={imgg}
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <div className="image_magnify_div">
                      <SideBySideMagnifier
                        imageSrc={image}
                        fillSpace="true"
                        smallImageSrc={image}
                        imageAlt="Example"
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    className="product_detail_div"
                  >
                    <ProductDetail
                      item={item}
                      updatedPrice={calculatePrice(
                        item.isPercentage,
                        item.isFixed,
                        item.price,
                        item.commissionAmount
                      )}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12} md={6}>
                    <Card className="w-100 float-right">
                      <Card.Body className={classes.p}>
                        <span style={{ fontSize: "1.1rem", color: "#B12704" }}>
                          PKR{" "}
                          {calculatePrice(
                            item.isPercentage,
                            item.isFixed,
                            item.price,
                            item.commissionAmount
                          )}
                        </span>
                        <span style={{ fontSize: "1.1rem", color: "#B12704" }}>
                          &nbsp;{item.priceUnit}
                        </span>
                        {item.delivery ? (
                          <p style={{ lineHeight: "20px" }}>
                            {/* <b>Delivery at PKR +</b> */}
                            {handleDisplayTransportationCharges(
                              item.transportCharges,
                              item.deliveryTimeLine,
                              item.locationByCity,
                              item.maxTransportationVolume
                            )}
                            {transCharges}
                            {/* {item.transportCharges.map((x) =>
                              x.transportLocatiion === location ? (
                                <b>{x.transportCharges}</b>
                              ) : (
                                "fghfgh"
                              )
                            )} */}
                            {"     "}
                            {/* for this product.{" "} */}
                            {/* <b>
                              {"   "}
                              {location}
                            </b> */}
                          </p>
                        ) : (
                          <p style={{ lineHeight: "20px" }}>
                            {"Delivery not available for this product"}
                          </p>
                        )}
                        <Form.Group className="init_quantity">
                          <Form.Label>Select Product Quantity</Form.Label>
                          {item.isMultipleAllow ? (
                            <Form.Control
                              input="number"
                              as="select"
                              placeholder="Quantity"
                              value={quantity}
                              name="quantity"
                              onChange={handleQuantityUpdate}
                              className="quantity-input"
                              disabled={
                                item.active &&
                                item.delivery &&
                                isDelivery &&
                                item.countInStock > 0
                                  ? false
                                  : true
                              }
                            >
                              {item.chooseMultiple > 1 ? (
                                <option>{item.minimumQuantity}</option>
                              ) : (
                                ""
                              )}

                              {item.maximumQuantity >= item.chooseMultiple ? (
                                item.minimumQuantity * item.chooseMultiple <=
                                item.maximumQuantity ? (
                                  <option>
                                    {item.minimumQuantity * item.chooseMultiple}
                                  </option>
                                ) : (
                                  // <option value={""} disabled></option>
                                  ""
                                )
                              ) : (
                                ""
                              )}

                              {array?.map((arr) => (
                                <option key={arr}>{arr}</option>
                              ))}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              type="number"
                              placeholder="Quantity"
                              value={quantity}
                              onChange={handleQuantityUpdate}
                              disabled={
                                item.active &&
                                item.delivery &&
                                isDelivery &&
                                item.countInStock > 0
                                  ? false
                                  : true
                              }
                              onBlur={(e) =>
                                onBlurQuantityUpdate(
                                  e,
                                  item.minimumQuantity,
                                  item.maximumQuantity
                                )
                              }
                              className="quantity-input"
                            />
                          )}
                        </Form.Group>
                        <Button
                          variant="warning"
                          className="add-cart-btn"
                          onClick={addToBasket}
                          disabled={
                            item.active &&
                            item.delivery &&
                            isDelivery &&
                            item.countInStock > 0
                              ? false
                              : true
                          }
                        >
                          <HiShoppingCart className="add-btn-icons" />
                          Add to cart
                        </Button>{" "}
                        <Button
                          variant="warning"
                          className="buy-now-btn"
                          onClick={handleBuyNow}
                          disabled={
                            item.active &&
                            item.delivery &&
                            isDelivery &&
                            item.countInStock > 0
                              ? false
                              : true
                          }
                        >
                          <AiFillPlaySquare className="add-btn-icons" />
                          Buy Now
                        </Button>
                        <hr />
                        <Button
                          variant="light"
                          className="addtolist_btn"
                          onClick={addToList}
                          disabled={item.active ? false : true}
                        >
                          {isLiked ? "Added to wishlist" : "Add to list"}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ paddingRight: "10px" }}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ paddingBottom: "0px" }}
                  >
                    <ProductInfo item={item} />
                  </Grid>
                  {/* {item.delivery ? (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        backgroundColor: "#fff",
                        margin: "0px 8px 0px 18px",
                      }}
                    >
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        {item.delivery ? (
                          <div className="product_policies policies-mt">
                            <h1 className="about mb-2">
                              Transportation Timeline{" "}
                            </h1>
                            <TableContainer className="pt-1 description-tables">
                              <Table size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Sr#</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Time Unit</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item.deliveryTimeLine?.map((location, i) => (
                                    <TableRow>
                                      <TableCell>{i + 1}</TableCell>
                                      <TableCell>
                                        {location.deliveryLocation}
                                      </TableCell>
                                      <TableCell>
                                        {location.deliveryTime}&nbsp;{" "}
                                        {location.deliveryTimeUnit}(s)
                                      </TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {item.transportationCharges ? (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        backgroundColor: "#fff",
                        margin: "0px 8px 0px 18px",
                      }}
                    >
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className="product_policies policies-mt">
                          <h1 className="about mb-2">
                            Transportation Charges{" "}
                          </h1>
                          <TableContainer className="pt-1 description-tables">
                            <Table size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Sr#</TableCell>
                                  <TableCell>Location</TableCell>
                                  <TableCell>Charges</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.transportCharges?.map((location, i) => (
                                  <TableRow>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>
                                      {location.transportLocatiion}
                                    </TableCell>
                                    <TableCell>
                                      {location.transportCharges}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )} */}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      backgroundColor: "#fff",
                      margin: "0px 8px 28px 18px",
                    }}
                  >
                    {item.transportationPolicy ? (
                      <div className="product_policies policies-mt">
                        <h1 className="about">Transportation Policy </h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.transportationPolicy,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {item.returnPolicy ? (
                      <div className="product_policies">
                        <h1 className="about">Return Policy </h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.returnPolicy,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {item.cancellationPolicy ? (
                      <div className="product_policies">
                        <h1 className="about">Cancellation Policy </h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.cancellationPolicy,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {item.breakingPolicy ? (
                      <div className="product_policies">
                        <h1 className="about">Breakage Policy </h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.breakingPolicy,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {item.weightDeviationPolicy ? (
                      <div className="product_policies">
                        <h1 className="about">Weight Deviation Policy </h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.weightDeviationPolicy,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {/* <div className="slider-heading-div">
          <h3 className="slider-heading" style={{ marginLeft: "0px" }}>
            Related Products
          </h3>
        </div> */}
      <Grid container item xs={12} className="ourServices-container">
        <Container className="wrapper prodouctsWrapper">
          <Grid item xs={12} className="Products-headings-and-btns-grid">
            <Typography className="productsWrapper-heading">
              Related Products
            </Typography>
            <Box style={{ display: "flex" }}>
              <ArrowBackIosIcon
                onClick={() => sliderRef3.current.slickNext()}
                className="slick-btns"
              />
              <ArrowForwardIosIcon
                onClick={() => sliderRef3.current.slickPrev()}
                className="slick-btns"
              />
            </Box>
          </Grid>
          <Grid container xs={12} className="products-cards-grid">
            {sliderProducts && sliderProducts.length >= 3 ? (
              <Slider {...settings3} ref={sliderRef3} className="slick-slider">
                {sliderProducts?.map(
                  (x, index) =>
                    x?._id !== prdctId && (
                      <Grid item key={index}>
                        <ProductsCard
                          index={index}
                          name={x?.name}
                          image={x?.image[0]}
                          price={x?.price}
                          catId={x?.categoryId?._id}
                          id={x?._id}
                          likes={x?.likes}
                        />
                      </Grid>
                    )
                )}
              </Slider>
            ) : (
              sliderProducts?.map(
                (x, index) =>
                  x?._id !== prdctId && (
                    <Grid xs={12} md={6} lg={4} item key={index}>
                      <ProductsCard
                        index={index}
                        name={x?.name}
                        image={x?.image[0]}
                        price={x?.price}
                        catId={x?.categoryId?._id}
                        id={x?._id}
                        likes={x?.likes}
                      />
                    </Grid>
                  )
              )
            )}
          </Grid>
        </Container>
      </Grid>
      {/* {sliderProducts
          // .slice(0, 15)
          .sort(() => Math.random() - 0.5)
          .map((similarProduct, k) => (
            <div className="slider-div" key={k}>
              <img
                src={similarProduct.image[0]}
                alt="product"
                className="slider-image"
                onClick={() =>
                  history.push(`/product-detail/${similarProduct._id}`)
                }
              />
            </div>
          ))} */}
    </>
  );
};
export default Products;
