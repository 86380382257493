import {
  USER_SIGNUP_LOADING,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_SUCCESS,
  USER_LOGIN_LOADING,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_DATA_LOADING,
  USER_DATA_FAIL,
  USER_DATA_SUCCESS,
  AUTH_LOADING,
  AUTH_FAIL,
  AUTH_SUCCESS,
} from "../types/userTypes";

const initialState = {
  loading: true,
  userData: {},
  message: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_SIGNUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data?.buyer,
        message: action.payload.message,
      };
    case USER_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USER_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data.user,
        message: action.payload.message,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data.buyer,
        message: action.payload.data.message,
      };
    case USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data.data,
        message: action.payload.data.message,
      };
    case AUTH_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
