import React from "react";
import "./Checkout.css";
import Subtotal from "./Subtotal";
import CheckoutProduct from "./CheckoutProduct";
import FlipMove from "react-flip-move";
import { useSelector } from "react-redux";
function Checkout() {
  const { basket } = useSelector((state) => state.basketReducer);
  console.log("basket", basket);
  const AnimatedCheckOut = React.forwardRef(({ item, index }, ref) => (
    <div ref={ref}>
      <CheckoutProduct
        key={`anim ${index} ${item.id}`}
        title={item.title}
        id={item.id}
        rating={item.rating}
        price={item.price}
        priceUnit={item.priceUnit}
        image={item.image}
        quantity={item.quantity}
        shippingCharges={item.shippingCharges}
        minimumQuantity={item.minimumQuantity}
        isMultipleAllow={item.isMultipleAllow}
        chooseMultiple={item.chooseMultiple}
        maximumQuantity={item.maximumQuantity}
        initialShippingCharges={item.initialShippingCharges}
        maxTransportationVolume={item.maxTransportationVolume}
        productId={item._id}
        array={item.array}
        deliveryTimeline={item.deliveryTimeline}
        estimatedDeliveryTime={item.estimatedDeliveryTime}
      />
    </div>
  ));

  return (
    <div className="checkout row">
      <div className="checkout__left col-lg-9 col-md-12 col-sm-12">
        {basket.length <= 0 ? (
          <div
            className="row"
            style={{
              margin: "0px 0px",
              padding: "30px",
              backgroundColor: "white",
            }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src="https://m.media-amazon.com/images/G/01/cart/empty/kettle-desaturated._CB445243794_.svg"
                alt=""
              />
            </div>
            <h4
              style={{ padding: "130px 30px 30px 30px" }}
              className="checkout__title"
            >
              Add products to cart
            </h4>

            <div className="col-6"></div>
          </div>
        ) : (
          <div
            style={{
              margin: "0px 0px",
              padding: "30px",
              backgroundColor: "white",
            }}
          >
            <h2 className="checkout__title">Shopping Cart</h2>
            <FlipMove
              staggerDelayBy={150}
              enterAnimation="accordionVertical"
              leaveAnimation="accordionVertical"
            >
              {basket?.map((item, i) => (
                <AnimatedCheckOut
                  key={`an ${i} ${item.id}`}
                  item={item}
                  index={i}
                  // className="col-lg-9 col-md-12 col-sm-12"
                />
              ))}
            </FlipMove>
          </div>
        )}
      </div>

      <div
        style={{ padding: "0px !important" }}
        className="col-lg-3 col-md-12 col-sm-12"
      >
        <Subtotal />
      </div>
    </div>
  );
}

export default Checkout;
