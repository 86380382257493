import { ADD_TO_RECENT_VIEW } from "../types/productTypes";

const initialState = {
  recentViewProduct: [],
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_RECENT_VIEW:
      // Ensure recentViewProduct is initialized as an array
      const currentRecentView = state.recentViewProduct || [];
      console.log("ADD_TO_RECENT_VIEW", action.item);
      // Check if any item in action.item is not already in the recent view
      const newItems = action.item.filter(
        (item) => !currentRecentView.some((product) => product._id === item._id)
      );

      if (newItems.length > 0) {
        return {
          ...state,
          recentViewProduct: [...currentRecentView, ...newItems],
        };
      }

      // If all items are already in the recent view, return the current state
      return state;

    default:
      return state;
  }
}
