import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import "./style.css";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { useSelector, useDispatch } from "react-redux";
const Index = ({ title, link, image }) => {
  // const [{ choosedLocation }] = useStateValue();
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const history = useHistory();
  const handleBtnClick = (val) => {
    history.push(`/shop/${val}/1/${choosedLocation}`);
  };
  return (
    <Grid
      container
      xs={12}
      className="ourServices-maain-grid"
      style={{
        background: `linear-gradient(to right, #208153, rgba(0, 0, 0, 0)), url(${image})`,
        cursor: "pointer",
      }}
      onClick={() => handleBtnClick(link)}
    >
      <Typography className="ourServices-text">{title}</Typography>
      <Button
        style={{
          border: " 1px solid white",
          borderRadius: "0px",
          color: "white ",
          height: "54px ",
          width: "148px ",
          marginTop: "20px ",
        }}
      >
        <Typography>see details</Typography>
      </Button>
    </Grid>
  );
};
export default Index;
