import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import deosaiLogo from "../../assets/desosaiLogo.png";
import { axiosClient } from "../../helper";

import classes from "./emailVerify.module.css";

class ResetPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    token: "",
    isLoading: false,
  };

  componentDidMount() {
    let id = this.props.match.params.code;
    this.setState({ token: id });
  }

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleForgetEmail = (e) => {
    e.preventDefault();
    const loginDetails = {
      newPassword: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: this.state.token,
    };
    if (!this.state.password || !this.state.confirmPassword) {
      toast.error("All field are required ");
    } else {
      if (this.state.password === this.state.confirmPassword) {
        this.setState({ isLoading: true });
        axiosClient()
          .post(`/resetPassword`, loginDetails)
          .then((response) => {
            this.setState({ isLoading: false });
            setTimeout(() => this.props.history.push(`/login`), 2000);
            toast.success(response.data.message);
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error(error.response.data.message);
          });
      } else {
        toast.error("Password must be same ");
      }
    }
  };
  render() {
    return (
      <>
        <div className="login">
          <ToastContainer autoClose={2000} />
          <Link to="/">
            <img
              style={{
                alignItems: "center",
                width: "180px",
                marginRight: "100%",
                marginLeft: "auto",
                marginTop: "0px",
                marginBottom: "0px",
              }}
              src={deosaiLogo}
              alt=""
            />
          </Link>
          <Container>
            <Row style={{ justifyContent: "center" }}>
              <Col sm={10} md={4}>
                <div className={classes.email_div}>
                  <h4 className={classes.logo}>Reset Password</h4>

                  <Card className={classes.forget_card}>
                    <Card.Title className={classes.forget_heading}>
                      Set a new password for your account
                    </Card.Title>
                    <Form>
                      <Form.Group
                        style={{ position: "relative" }}
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Enter New Password"
                          name="password"
                          className={classes.forget_form_control}
                          value={this.state.password}
                          onChange={this.onChangeEmail}
                        />
                        <span className={classes.form_design}>
                          <LockOutlinedIcon />
                        </span>
                      </Form.Group>
                    </Form>

                    <Form>
                      <Form.Group
                        style={{ position: "relative" }}
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Confirm New Password"
                          name="confirmPassword"
                          className={classes.forget_form_control}
                          value={this.state.confirmPassword}
                          onChange={this.onChangeEmail}
                        />
                        <span className={classes.form_design}>
                          <LockOutlinedIcon />
                        </span>
                      </Form.Group>
                    </Form>

                    <Button
                      onClick={this.handleForgetEmail}
                      className={classes.forgetbtn}
                      style={{ boxShadow: "none" }}
                      type="submit"
                      disabled={this.state.isLoading ? true : false}
                    >
                      {this.state.isLoading === true ? (
                        <span className="d-flex align-items-center justify-content-center">
                          <span className="mr-2">Please wait</span>
                          <CircularProgress
                            className="text-white"
                            color="inherit"
                            size={18}
                          />
                        </span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default ResetPassword;
