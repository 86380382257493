export const USER_SIGNUP_LOADING = "USER_SIGNUP_LOADING";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_FAIL = "USER_DATA_FAIL";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
