import React, { useState } from "react";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Card, Image } from "react-bootstrap";
import StarIcon from "@material-ui/icons/Star";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ProductImg from "../../assets/product.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarIcon1 from "@material-ui/icons/Star";
import { AddToWishList } from "../../redux/actions/wishListAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.css";

const Index = ({ name, image, price, catId, id, index, likes }) => {
  ////////////////////////////////////////////////////
  const [loader, setLoader] = useState({
    loading: false,
    index: "",
  });
  ////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const history = useHistory();
  ////////////////////////////////////////////////////
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const { wishList } = useSelector((state) => state.userReducer);

  const handleBtnClick = (id) => {
    history.push(`/product-detail/${id}`);
  };
  ////////////////////////////////////////////////////
  const handleWishList = async (count) => {
    setLoader({
      loading: true,
      index: count,
    });
    await dispatch(AddToWishList(id));
    setLoader({
      loading: false,
      index: "",
    });
  };
  return (
    <Grid className="Products-cars-main-grid">
      <Grid item xs={12} className="product-card-like-and-cart-btns">
        <Box className="product-card-like-and-addCart-icon-box">
          {loader.loading && loader.index === index ? (
            <CircularProgress size={20} />
          ) : (
            <React.Fragment>
              {likes?.find((o) => o?.buyerId === userData?._id) ? (
                <StarIcon1
                  onClick={() => handleWishList(index)}
                  className="product-card-like-and-addCart-icon"
                />
              ) : (
                <StarBorderIcon
                  onClick={() => handleWishList(index)}
                  className="product-card-like-and-addCart-icon"
                />
              )}
            </React.Fragment>
          )}
        </Box>
        <Box className="product-card-like-and-addCart-icon-box">
          <LocalMallIcon className="product-card-like-and-addCart-icon" />
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className="product-card-img-grid"
        onClick={() => handleBtnClick(id)}
      >
        {/* <img src={ProductImg} className="product-card-img" /> */}
        <Image src={image} className="product-card-img" />
      </Grid>
      <Grid
        container
        item
        xs={12}
        className="product-card-name-price-rating-grid"
      >
        <Grid className="product-name-and-rating">
          <Typography className="productName-ProductPrice product-name">
            {name}
          </Typography>
          <Box>
            <StarIcon className="greenColor" />
            <StarIcon className="greenColor" />
            <StarIcon className="greenColor" />
            <StarIcon className="greenColor" />
            <StarIcon className="greenColor" />
          </Box>
        </Grid>
        <Typography className="productName-ProductPrice">Rs {price}</Typography>
        <Button onClick={() => handleBtnClick(id)} className="ourServices-btn">
          see details
        </Button>
      </Grid>
    </Grid>
  );
};
export default Index;
