import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ReactInputMask from "react-input-mask";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import deosaiLogo from "./assets/desosaiLogo.png";
import { UserSignUp } from "./redux/actions/userAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Signup.css";

function Signup() {
  const [signUpRes, setSignUpRes] = useState();
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Name is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
    phone: yup
      .string("Enter your phone number")
      .matches(/^\+92 \d{3} \d{7}$/, "Phone number is not valid")
      .required("Phone numbe is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let response = await dispatch(UserSignUp(values));
      setSignUpRes(response?.data);
      setLoading(false);
      if (response.data.message === "User already exists") {
        setOpen(true);
        let timmer = setTimeout(() => {
          history.push("/login");
        }, 1500);
      } else if (response.data.message === "user created and token generated") {
        setOpen(true);
        localStorage.setItem(`token`, response?.data?.token);
        localStorage.setItem(`_id`, response?.data?.buyer?._id);
        localStorage.setItem(`userId`, response?.data?.buyer?._id);
        history.push("/");
      }
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <div className="signup">
        <img
          className="signup__logo"
          src={deosaiLogo}
          alt=""
          onClick={() => history.push("/")}
        />
        <div className="signup__container">
          <h2>Create account</h2>
          <form onSubmit={formik.handleSubmit} autocomplete="off">
            <h6>
              Name <span className="required">*</span>
            </h6>
            <input
              required
              id="name"
              type="text"
              className="setInputField"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <h6>
              E-mail <span className="required">*</span>
            </h6>
            <input
              required
              id="email"
              type="email"
              className="setInputField"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <h6>
              Password <span className="required">*</span>
            </h6>
            <input
              required
              autocomplete="new-password"
              id="password"
              type="password"
              className="setInputField"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <h6>
              Phone <span className="required">*</span>
            </h6>
            <ReactInputMask
              id="phone"
              mask={"+\\92 999 9999999"}
              alwaysShowMask={false}
              type="text"
              className="setInputField"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            >
              {() => (
                <input
                  required
                  name="phone"
                  className="setInputField"
                  value={formik.values.phone}
                />
              )}
            </ReactInputMask>
            <button type="submit" className="signup__signInButton">
              {loading ? <CircularProgress size={15} /> : "Sign Up"}
            </button>
          </form>
          <p>
            Already have an acccount? <Link to="/login">Login</Link>
          </p>
          <p>
            By continuing, you agree to iown.store's Terms & Conditions of Use
            and Privacy Policy.
          </p>
        </div>
      </div>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        TransitionComponent={transition}
        key={transition ? transition.name : ""}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          className="explore-compaigns-snackbar"
          style={{ backgroundColor: "black" }}
          sx={{ width: "100%" }}
        >
          {signUpRes?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Signup;
