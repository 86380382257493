export const initialState = {
  basket: [],
  user: null,
  totalShipping: 0,
  choosedLocation: "Islamabad",
  selectedCityCode: "ISB",
  selectedCityRegion: "Islamabad",
};

export const getBasketTotal = (basket) =>
  basket?.reduce(
    (totalPrice, item) => totalPrice + item.price * item.quantity,
    0
  );

const reducer = (state, action) => {
  switch (action?.type) {
    case "ADD_TO_BASKET":
      return {
        ...state,
        basket: [...state.basket, action.item],
      };

    case "UPDATE_BASKET":
      var bIndex = state.basket.findIndex((b) => b.id === action.item.id);

      if (bIndex > -1) {
        const dummyState = state;
        dummyState.basket[bIndex] = action.item;
        return { ...dummyState };
      } else {
        return { ...state };
      }

    case "REMOVE_FROM_BASKET":
      // Find first item index with given id
      const index = state.basket.findIndex(
        (basketItem) => basketItem.id === action.id
      );
      // Copy of current basket
      let newBasket = [...state.basket];

      if (index >= 0) {
        newBasket.splice(index, 1);
      } else {
        console.warn(`Cant remove product`);
      }
      return {
        ...state,
        basket: newBasket,
      };

    case "EMPTY_BASKET":
      return {
        ...state,
        basket: [],
        totalShipping: 0,
      };

    case "UPDATE_CHECKOUT_QUANTITY":
      var indexx = state.basket.findIndex((b) => b.id === action.id);
      if (indexx > -1) {
        state.basket[indexx].quantity = parseInt(action.quantity);
        return { ...state };
      } else {
        return { ...state };
      }

    case "UPDATE_QUANTITY":
      var qIndex = state.basket.findIndex((b) => b.id === action.id);

      if (qIndex > -1) {
        state.basket[qIndex].quantity += parseInt(action.quantity);
        return { ...state };
      } else {
        return { ...state };
      }

    case "UPDATE_ADMIN_COMMISSION":
      var cIndex = state.basket.findIndex((b) => b.id === action.id);

      var initCommission = state.basket.filter(
        (b) =>
          // b.id === action.id ? b.initialCommission : b.id
          b.id === action.id
      );

      if (cIndex > -1) {
        state.basket[cIndex].adminCommissionAmount =
          action.quantity * initCommission[0].initialCommission;

        return { ...state };
      } else {
        return { ...state };
      }

    case "SET_USER":
      return { ...state, user: action.user };

    case "ADD_SHIPPING":
      return {
        ...state,
        totalShipping:
          Number(action.item.shippingCharges) > 0
            ? Number(state.totalShipping) + Number(action.item.shippingCharges)
            : 0 + Number(state.totalShipping),
      };

    case "UPDATE_SHIPPING":
      var usIndex = state.basket.findIndex((b) => b.id === action.id);

      if (usIndex > -1) {
        state.basket[usIndex].shippingCharges = parseInt(
          action.item.shippingCharges
        );
        state.totalShipping = state.basket?.reduce(
          (a, v) => (a = a + v.shippingCharges),
          0
        );
        return { ...state };
      } else {
        return { ...state };
      }

    case "REMOVE_SHIPPING":
      return {
        ...state,
        totalShipping:
          Number(state.totalShipping) - Number(action.item.shippingCharges),
      };

    case "CHANGE_LOCATION":
      return {
        ...state,
        choosedLocation: action.item.choosedLocation,
        selectedCityCode: action.item.selectedCityCode,
        selectedCityRegion: action.item.selectedCityRegion,
      };
    default:
      return state;
  }
};

export default reducer;
