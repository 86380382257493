import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

function Row(props) {
  const { order } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow className="main_rows">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{order?.refId}</TableCell>
        <TableCell>
          {order?.createdAt ? (
            <>
              <span> {new Date(order?.createdAt).toDateString()}</span>
              <br />
              <span className="mt-2">
                {new Date(order?.createdAt).toLocaleTimeString()}
              </span>
            </>
          ) : (
            <span>--/--</span>
          )}
        </TableCell>
        <TableCell>{parseInt(order?.totalPrice)}&nbsp;rs/-</TableCell>
        {/* <TableCell>{}</TableCell> */}
      </TableRow>
      <TableRow style={{ backgroundColor: "#fff" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <h6 style={{ fontWeight: "600" }}>Products Details</h6>
              <Table
                size="small"
                aria-label="purchases"
                className="collapse-rows"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell style={{ maxWidth: "275px" }}>
                      Product Name
                    </TableCell>
                    {/* <TableCell align="center">Order Status</TableCell> */}
                    <TableCell>Seller Name</TableCell>
                    <TableCell>Delivery Time</TableCell>
                    <TableCell>Delivery Charges</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.orderItems?.map((orderItem) => (
                    <TableRow key={orderItem._id} style={{ height: "50px" }}>
                      <TableCell>
                        <img
                          src={orderItem.productId.image[0]}
                          alt={orderItem.productId.name}
                          style={{ maxWidth: "50px", maxHeight: "50px" }}
                        />
                      </TableCell>
                      <TableCell style={{ maxWidth: "275px" }}>
                        <strong>{orderItem.quantity} x </strong>
                        {orderItem.productName}
                      </TableCell>
                      <TableCell>
                        {orderItem.sellerId.fullName ? (
                          orderItem.sellerId.fullName
                        ) : orderItem.sellerId.name ? (
                          orderItem.sellerId.name
                        ) : (
                          <span>N/A</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {orderItem.estimatedDeliveryTime ? (
                          <>
                            <span>
                              {new Date(
                                orderItem.estimatedDeliveryTime
                              )?.toLocaleDateString()}
                            </span>
                            <br />
                            <span>
                              {" "}
                              {new Date(
                                orderItem.estimatedDeliveryTime
                              )?.toLocaleTimeString()}
                            </span>
                          </>
                        ) : (
                          <span>--/--</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {orderItem.productShippingCharges}&nbsp;rs/-
                      </TableCell>
                      <TableCell>
                        {/* {`${orderItem.quantity} x ${orderItem.unitPrice} = `} */}
                        {Math.round(orderItem.quantity * orderItem.unitPrice)}
                        &nbsp;rs/-
                      </TableCell>
                      <TableCell>{orderItem.tax}&nbsp;rs/-</TableCell>
                      <TableCell>{orderItem.orderStatusId.status}</TableCell>
                      {orderItem.orderStatusId.status === "Awaiting" ? (
                        <TableCell>
                          <Button
                            disabled={
                              orderItem.isReturnRequestGenerated === true
                            }
                            className="cancel_button order_return_button"
                          >
                            Cancel Order Request
                          </Button>
                        </TableCell>
                      ) : orderItem.orderStatusId.status === "Delivered" ? (
                        <TableCell>
                          <Link
                            to={`/return-request/${orderItem.orderStatusId.orderId}/${orderItem.productId._id}`}
                          >
                            <Button
                              disabled={
                                orderItem.isReturnRequestGenerated === true
                              }
                              className="return_button order_return_button"
                            >
                              {orderItem.isReturnRequestGenerated === true
                                ? "Return Requested"
                                : "Return Request"}
                            </Button>
                          </Link>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default Row;
