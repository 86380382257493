export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const UPDATE_BASKET = "UPDATE_BASKET";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";
export const EMPTY_BASKET = "EMPTY_BASKET";
export const UPDATE_CHECKOUT_QUANTITY = "UPDATE_CHECKOUT_QUANTITY";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_ADMIN_COMMISSION = "UPDATE_ADMIN_COMMISSION";
export const SET_USER = "SET_USER";
export const ADD_SHIPPING = "ADD_SHIPPING";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const REMOVE_SHIPPING = "REMOVE_SHIPPING";
