import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, Button, Box, Container } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./style.css";
import { useStateValue } from "../../StateProvider";
import { getCategories } from "../../redux/actions/categoriesAction";
import { Auth } from "../../redux/actions/userAction";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import NavBar from "../../components/NewNavBar";
import OurServices from "../../components/OurServicesCard";
import ProductsCard from "../../components/ProductsCard";
import Footer from "../../components/NewFooter";
import { axiosClient } from "../../helper";

const Index = () => {
  ///////////////////////////////////////////////////////////////////////////////////////
  // const [{ choosedLocation }] = useStateValue();
  const [category, setCategory] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  console.log({ featuredProducts });
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  ///////////////////////////////////////////////////////////////////////////////////////
  const history = useHistory();
  const dispatch = useDispatch();
  const sliderRef1 = useRef();
  const sliderRef2 = useRef();
  const sliderRef3 = useRef();
  ///////////////////////////////////////////////////////////////////////////////////////
  const { categories } = useSelector((state) => state.categoriesReducer);
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const { recentViewProduct } = useSelector((state) => state.productReducer);
  ///////////////////////////////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   getProducts();
  // }, [choosedLocation]);
  // console.log("choosedLocation", choosedLocation, featuredProducts);
  console.log("recentViewProduct", recentViewProduct);
  useEffect(() => {
    dispatch(Auth());
    dispatch(getCategories());
  }, []);
  useEffect(() => {
    axiosClient()
      .get(`/getFeaturedProducts/${choosedLocation}`)
      .then((res) => {
        setFeaturedProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [choosedLocation]);

  const handleContactUs = () => {
    window.open("https://api.whatsapp.com/send?phone=+923234836348&text=%20");
  };
  var settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: categories?.categories?.length <= 3 ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3300,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const ProductsArray = [
    {
      cards: <ProductsCard />,
    },
    {
      cards: <ProductsCard />,
    },
    {
      cards: <ProductsCard />,
    },
    {
      cards: <ProductsCard />,
    },
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  // const getProducts = () => {
  //   axiosClient()
  //     .get(`/getFeaturedProducts/${choosedLocation}`)
  //     .then((res) => {
  //       setFeaturedProducts(res.data.products);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   axiosClient()
  //     .get("/getTopSellingProducts")
  //     .then((res) => {
  //       setTopSellingProducts(res.data.products);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  ///////////////////////////////////////////////////////////////////////////////////////
  return (
    <Grid container xs={12}>
      {/* <Header />
      <NavBar /> */}
      <Grid container item xs={12} className="landing-page-banner">
        <Grid item xs={12} sm={10} md={6} className="banner-text-and-btn-grid">
          <Box>
            <Box>
              <Typography className="banner-text-1">lets build your</Typography>
              <Typography className="banner-text-2">dream together</Typography>
            </Box>
            <Box className="main-banner-btns">
              <Button
                variant="contained"
                className="banner-contactUs-btn"
                onClick={handleContactUs}
              >
                contact us now
              </Button>
              <Button variant="contained" className="banner-learnMore-btn">
                learn more
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* ------------------------------------------------- */}
      <Grid container item xs={12} className="ourServices-container">
        <Container className="wrapper prodouctsWrapper">
          <Grid item xs={12} className="Products-headings-and-btns-grid">
            <Typography className="productsWrapper-heading">
              Our Services
            </Typography>
            <Box style={{ display: "flex" }}>
              <ArrowBackIosIcon
                onClick={() => sliderRef1.current.slickNext()}
                className="slick-btns"
              />
              <ArrowForwardIosIcon
                onClick={() => sliderRef1.current.slickPrev()}
                className="slick-btns"
              />
            </Box>
          </Grid>
          <Grid item xs={12} className="products-cards-grid">
            <Slider {...settings1} ref={sliderRef1} className="slick-slider">
              {categories?.categories?.map((x) => (
                <Grid>
                  <OurServices title={x?.name} link={x?._id} image={x?.image} />
                </Grid>
              ))}
            </Slider>
          </Grid>
        </Container>
      </Grid>
      {/* ------------------------------------------------- */}
      <Grid container item xs={12} className="ourServices-container">
        <Container className="wrapper prodouctsWrapper">
          <Grid item xs={12} className="Products-headings-and-btns-grid">
            <Typography className="productsWrapper-heading">
              Featured Products
            </Typography>
            <Box style={{ display: "flex" }}>
              <ArrowBackIosIcon
                onClick={() => sliderRef2.current.slickNext()}
                className="slick-btns"
              />
              <ArrowForwardIosIcon
                onClick={() => sliderRef2.current.slickPrev()}
                className="slick-btns"
              />
            </Box>
          </Grid>
          <Grid item xs={12} className="products-cards-grid">
            <Slider {...settings2} ref={sliderRef2} className="slick-slider">
              {featuredProducts?.map((x, index) => (
                <Grid>
                  <ProductsCard
                    index={index}
                    name={x?.name}
                    image={x?.image[0]}
                    price={x?.price}
                    catId={x?.categoryId}
                    id={x?._id}
                    likes={x?.likes}
                  />
                </Grid>
              ))}
            </Slider>
          </Grid>
        </Container>
      </Grid>
      {/* ------------------------------------------------- */}
      <Grid container item xs={12} className="ourServices-container">
        <Container className="wrapper prodouctsWrapper">
          <Grid item xs={12} className="Products-headings-and-btns-grid">
            <Typography className="productsWrapper-heading">
              Recently Viewed
            </Typography>
            <Box style={{ display: "flex" }}>
              <ArrowBackIosIcon
                onClick={() => sliderRef3.current.slickNext()}
                className="slick-btns"
              />
              <ArrowForwardIosIcon
                onClick={() => sliderRef3.current.slickPrev()}
                className="slick-btns"
              />
            </Box>
          </Grid>
          <Grid item xs={12} className="products-cards-grid">
            <Slider {...settings3} ref={sliderRef3} className="slick-slider">
              {recentViewProduct?.map(
                (x, index) =>
                  x.locationByCity?.includes(choosedLocation) ||
                  (x.locationByCity?.includes("All") && (
                    <Grid key={index}>
                      {" "}
                      <ProductsCard
                        index={index}
                        name={x?.name}
                        image={x?.image[0]}
                        price={x?.price}
                        catId={x?.categoryId?._id}
                        id={x?._id}
                        likes={x?.likes}
                      />
                    </Grid>
                  ))
              )}
            </Slider>
          </Grid>
        </Container>
      </Grid>
      {/* <Footer /> */}
    </Grid>
  );
};
export default Index;
