import React, { useState,useEffect,useRef } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Services from "../../assets/services.png";
import Kitchen from "../../assets/kitchen.png";
import Sanitary from "../../assets/sanitary.png";
import Furniture from "../../assets/furniture.png";
import Electricity from "../../assets/electricity.png";
import Finishing from "../../assets/finishing.png";
import GrayStructure from "../../assets/grey-structure.png";
import "./style.css";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import CallIcon from "@material-ui/icons/Call";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosClient } from "../../helper";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const OurServicesArray = [
  {
    icon: Services,
    label: "services",
  },
  {
    icon: Kitchen,
    label: "kitchen fitting",
  },
  {
    icon: Sanitary,
    label: "sanitary fitting",
  },
  {
    icon: Furniture,
    label: "wood & glass",
  },
  {
    icon: Furniture,
    label: "Furniture",
  },
  {
    icon: Electricity,
    label: "electrical & security",
  },
  {
    icon: Finishing,
    label: "finishing",
  },
  {
    icon: GrayStructure,
    label: "grey structure",
  },
];
const NavLinksArray = [
  {
    text: "home",
  },
  {
    text: "about",
  },
  {
    text: "services",
  },
  {
    text: "faqs",
  },
  {
    text: "blog",
  },
  {
    text: "contact",
  },
];
const DrawerList = [
  {
    text: "HOME",
    icon: <HomeIcon style={{ color: "#208153" }} />,
    link: "home",
  },
  {
    text: "ABOUT",
    icon: <InfoIcon style={{ color: "#208153" }} />,
    link: "about",
  },
  {
    text: "SERVICES",
    icon: <ContactSupportIcon style={{ color: "#208153" }} />,
    link: "services",
  },
  {
    text: "FAQs",
    icon: <LiveHelpIcon style={{ color: "#208153" }} />,
    link: "faqs",
  },
  {
    text: "BLOG",
    icon: <CallIcon style={{ color: "#208153" }} />,
    link: "blog",
  },
  {
    text: "CONTACT",
    icon: <HomeIcon style={{ color: "#208153" }} />,
    link: "contact",
  },
];

const Index = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [state, setState] = useState({
    right: false,
  });

  const classes = useStyles();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);

  const { categories } = useSelector((state) => state.categoriesReducer);
  const { choosedLocation } = useSelector((state) => state.generalReducer);
  const [subCategory, setSubcategory] = useState([]);
  const wrapperRef = useRef(null);
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const handleClickOutside = (event) => {
    
    const { current: wrap } = wrapperRef;
    
    if (wrap && !wrap.contains(event.target)) {
      setToggleMenu(false);
    }
  };
  console.log("subCategory", subCategory);
  const handleDrawerClick = (val) => {};
  const handleNavigate = (val) => {
    if (val == "iWant") {
      history.push(`/iwant`);
    } else if (val == "vendor") {
      window.open(`https://seller.iown.store/`);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  ////////////////////////////////////////////////////////////////////
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {DrawerList?.map((x) => (
          <ListItem onClick={() => handleDrawerClick(x?.link)}>
            <ListItemIcon>{x?.icon}</ListItemIcon>
            <ListItemText>{x?.text}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
  ////////////////////////////////////////////////////////////////////

  const handleMouseEnter = (id) => {
    console.log("subcata id", id);
    getSubcategoryByCategoryId(id);
  };
  const getSubcategoryByCategoryId = (id) => {
    axiosClient()
      .get(`/getSubCategoriesByCategoryId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSubcategory(response.data.subCategory);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleMouseLeave = () => {};

  return (
    <Grid contsiner xs={12} className="navBar-main-grid">
      <Grid container xs={12} className="navBar-wrapper">
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          className="navBar-short-list"
          style={{ position: "relative" }}
        >
          <Grid item xs={12} className="ourServices-dropdown" >
            Our Services{" "}
            {toggleMenu ? (
              <CloseIcon
                onClick={() => handleToggleMenu(false)}
                className="ourServices-Menu-Icon"
              />
            ) : (
              <MenuIcon
                onClick={() => handleToggleMenu(true)}
                className="ourServices-Menu-Icon"
              />
            )}
          </Grid>
          <Grid container item xs={12} className="ourServices-dropDown"ref={wrapperRef}>
            <>
              {toggleMenu ? (
                <React.Fragment>
                  {categories?.categories?.map((category, index) => (
                    <div key={category?._id} className="dropdown-item">
                      <Button
                        className="ourServices-dropDown-select"
                        onMouseOver={() => handleMouseEnter(category?._id)}
                        onClick={() => {
                          history.push(
                            `/shop/${category?._id}/${pageNumber}/${choosedLocation}`
                          );
                          setToggleMenu(false);
                        }}
                      >
                        <Typography className="dropdown-options-text">
                          {category?.name}
                        </Typography>
                      </Button>
                      {subCategory && subCategory.length > 0 && (
                        <ul className="subcategories-dropdown">
                          {subCategory.map((subcategory) => (
                            <li key={subcategory?._id}>
                              <Button
                                className="ourServices-subdropDown-select"
                                onClick={() => {
                                  history.push(
                                    `/subshop/${category?._id}/${subcategory?._id}/${pageNumber}/${choosedLocation}`
                                  );
                                  setToggleMenu(false);
                                }}
                              >
                                <Typography className="dropdown-options-text">
                                  {subcategory.subCategoryName}
                                </Typography>
                              </Button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </React.Fragment>
              ) : (
                ""
              )}
            </>
          </Grid>

          {/* <Grid container item xs={12} className="ourServices-dropDown">
            <>
              {toggleMenu ? (
                <React.Fragment>
                  {categories?.categories?.map((x, index) => (
                    <>
                      <Button
                        key={x?._id}
                        className="ourServices-dropDown-select"
                        onClick={() => {
                          history.push(
                            `/shop/${x?._id}/${pageNumber}/${choosedLocation}`
                          );
                          setToggleMenu(false);
                        }}
                      >
                        <Typography className="dropdown-options-text">
                          {x?.name}
                        </Typography>
                      </Button>
                    </>
                  ))}
                </React.Fragment>
              ) : (
                ""
              )}
            </>
          </Grid> */}
        </Grid>
        <Grid item xs={3} sm={2} md={6} className="navBar-links">
          <Grid className="NavLinksOnLargScreen">
            {NavLinksArray?.map((y) => (
              <Button className="navBar-link">{y?.text}</Button>
            ))}
          </Grid>
          <div className="NavLinksOnSmallScreen">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon className="ourServices-Menu-Icon" />
                </Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </div>
        </Grid>
        <Grid item xs={9} sm={6} md={3} className="navBar-two-buttons">
          <Button
            variant="contained"
            className="iWant-btn"
            onClick={() => handleNavigate("iWant")}
          >
            iWant
          </Button>
          <Button
            variant="contained"
            className="become-vendor-btn"
            onClick={() => handleNavigate("vendor")}
          >
            Become A Vendor
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Index;
