import {
  ADD_TO_WISHLIST_LOADING,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAIL,
} from "../types/wishListTypes";
import { axiosClient } from "../../helper";
////////////////////////////////////////////////
export const AddToWishListLoading = () => ({
  type: ADD_TO_WISHLIST_LOADING,
});
export const AddToWishListSuccess = (payload) => ({
  type: ADD_TO_WISHLIST_SUCCESS,
  payload,
});
export const AddToWishListFail = () => ({
  type: ADD_TO_WISHLIST_FAIL,
});
////////////////////////////////////////////////
export const AddToWishList = (formData) => async (dispatch) => {
  dispatch(AddToWishListLoading());
  try {
    const result = await axiosClient().post(`/addProductToWishList`, {
      productId: formData,
    });
    if (result) {
      dispatch(AddToWishListSuccess(result));
    }
    return result;
  } catch (err) {
    dispatch(AddToWishListFail(err));
    return err.response;
  }
};
