import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { axiosClient } from "./helper";
import deosaiLogo from "./assets/desosaiLogo.png";
import { UserLogin } from "./redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

function Login() {
  const [loginRes, setLoginRes] = useState();
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  console.log("loginRes", loginRes);
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let response = await dispatch(UserLogin(values));
      await setLoginRes(response?.data);
      setOpen(true);
      setLoading(false);
      if (response?.data?.status === "success") {
        localStorage.setItem(`token`, response?.data?.token);
        localStorage.setItem(`_id`, response?.data?.user?._id);
        localStorage.setItem(`userId`, response?.data?.user?._id);
        history.push("/");
      } else if (response?.data?.status === false) {
        window.location("/login");
      }
    },
  });

  const register = (e) => {
    e.preventDefault();
    history.push("/signup");
  };

  const handleClose = () => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <div className="login">
        <img
          style={{
            objectFit: "contain",
            width: "80px",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            cursor: "pointer",
          }}
          src={deosaiLogo}
          alt=""
          onClick={() => history.push("/")}
        />

        <div className="login__container">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <h1>Sign in</h1>
            <h6>Email</h6>
            <input
              required
              id="email"
              type="email"
              className="setInputField"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <h6>Password</h6>
            <input
              required
              autocomplete="new-password"
              id="password"
              type="password"
              className="setInputField"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <button type="submit" className="login__signInButton">
              {loading ? <CircularProgress size={15} /> : "Sign In"}
            </button>
          </form>
          <Link to="/email-verify" className=" forgot">
            Forgot Password ?
          </Link>
          <p>
            By continuing, you agree to iown.store's Terms & Conditions of use
            and Privacy Policy.
          </p>
          <button onClick={register} className="login__registerButton">
            Create your Iown Account
          </button>{" "}
        </div>
      </div>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        TransitionComponent={transition}
        key={transition ? transition.name : ""}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          className="explore-compaigns-snackbar"
          style={{ backgroundColor: "black" }}
          sx={{ width: "100%" }}
        >
          {loginRes?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
