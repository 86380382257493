import {
  ADD_TO_BASKET,
  UPDATE_BASKET,
  REMOVE_FROM_BASKET,
  EMPTY_BASKET,
  UPDATE_CHECKOUT_QUANTITY,
  UPDATE_QUANTITY,
  UPDATE_ADMIN_COMMISSION,
  SET_USER,
  ADD_SHIPPING,
  UPDATE_SHIPPING,
  REMOVE_SHIPPING,
} from "../types/basketTypes";

export const addToBaskets = ({ item }) => {
  console.log("item", item);
  return {
    type: ADD_TO_BASKET,
    item: item,
  };
};
export const updateBasket = ({ item }) => {
  return {
    type: UPDATE_BASKET,
    item: item,
  };
};
export const removeBasket = (id) => {
  return {
    type: REMOVE_FROM_BASKET,
    id: id,
  };
};
export const emptyBasket = () => {
  return {
    type: EMPTY_BASKET,
  };
};
export const updateCheckoutQuantity = ({ id, quantity }) => {
  return {
    type: UPDATE_CHECKOUT_QUANTITY,
    id: id,
    quantity: quantity,
  };
};
export const addShipping = ({ item }) => {
  return {
    type: ADD_SHIPPING,
    item: item,
  };
};
export const updateShipping = ({ id, item }) => {
  return {
    type: UPDATE_SHIPPING,
    item: item,
    id: id,
  };
};
export const removeShipping = ({ item }) => {
  return {
    type: REMOVE_SHIPPING,
    item: item,
  };
};

export const updateAdminCommission = ({ id, quantity }) => {
  return {
    type: UPDATE_ADMIN_COMMISSION,
    id: id,
    quantity: quantity,
  };
};
