import React from "react";
import { Form } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import "./Checkout.css";
// import CreditPayment from "./CreditPayment";

const FirstCard = (props) => {
  return (
    <div className="firstcard-div">
      <div className="sweet-heading">
        <h3>Select a Payment Method</h3>
      </div>
      {/* <div className="sweetheadingmessage">
        <h5 className="accountState">Current Balance: RS40,000 PKR</h5>
        <span className="stateMessage">Add money to your account balance</span>
      </div> */}
      <ul className="sweetAlertUl">
        <div className="li-wrap" style={{ marginBottom: "1.3%" }}>
          <li
            className="sweetAlertLi"
            value="Cash"
            checked={props.creditExp === "Cash"}
            onChange={props.onChangePaymentMethod}
            // onClick={props.creditExp === "Cash"}
          >
            <div className="sweet-popup">
              <Form.Check
                type="radio"
                name="paymentSelect"
                value="Cash"
                checked={props.creditExp === "Cash"}
                onChange={props.onChangePaymentMethod}
              />
              <h5>Cash on Delivery</h5>
            </div>
          </li>
        </div>
        {/* <div className="li-wrap">
          <li
            className={
              props.creditExp === "Card"
                ? "sweetAlertLi sweetAlertLiExpand"
                : "sweetAlertLi"
            }
            // onClick={props.creditExp === "Card"}
            value="Card"
            checked={props.creditExp === "Cash"}
            onChange={props.onChangePaymentMethod}
          >
            <div className="sweet-popup">
              <Form.Check
                type="radio"
                name="paymentSelect"
                value="Card"
                checked={props.creditExp === "Card"}
                onChange={props.onChangePaymentMethod}
              />
              <h5>Credit or Debit Card</h5>
            </div>
            {props.creditExp === "Card" ? (
              <CreditPayment
                handleInputChange={props.handleInputChange}
                cardNumber={props.cardNumber}
                onChangeCardNumber={props.onChangeCardNumber}
                nameOnCard={props.nameOnCard}
                onChangeNameOnCard={props.onChangeNameOnCard}
                cvc={props.cvc}
                onChangeCvc={props.onChangeCvc}
                expiry={props.expiry}
                onChangeExpiry={props.onChangeExpiry}
              />
            ) : null}
          </li>
        </div> */}
        {/* <li
          className={`sweetAlertLi ${props.jazzExp ? "jazzExp" : ""}`}
          onClick={props.JazzCashExpand}
        >
          <div className="sweet-popup">
            <input
              type="radio"
              checked={props.jazzExp ? "checked" : null}
              name="paymentSelect"
              onChange={props.JazzCashExpand}
            />
            <h3>JazzCash</h3>
          </div>
          {/* {props.jazzExp ? <JazzCashPayment /> : null} */}
        {/* </li> */}
      </ul>
      {/* <div className="sweetButtons">
        <button onClick={props.onCancel} className="cancel">
          Cancel
        </button>
        <Button
          disabled={props.conti & props.formValid ? null : "disabled"}
          className="continue"
          onClick={props.handleFirstCardContinue}
        >
          Continue
        </Button>
      </div> */}
    </div>
  );
};

export default FirstCard;
